import { FC } from 'react';
import { BidAuctionnerItemProps } from './props';
import Wrapper from '../../../Common/wrapper';
import styled from 'styled-components';
import Image from '../../../Common/Image';
import { moment } from '../../../../helpers/moment';
import ApiService from '../../../../services/api';
import ConfirmationPopover from '../../../Common/ConfirmationPopover';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { StageLot } from '../../../../Interfaces/stageLot';
import BidInformation from '../BidInformation';
import { useTranslation } from 'react-i18next';
import Badge from '../../../Common/Badge';
import { isDeclinedBid } from '../../../../helpers/declined';

const stagesCanDeleteBid: StageLot[] = [
    StageLot.paused,
    StageLot.started,
    StageLot.timeEnded,
    StageLot.random_close_period,
    StageLot.random_period,
];

const Wrapped = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }
`;

const BidItemAuctionner: FC<BidAuctionnerItemProps> = ({
    bid,
    index,
    auctionNoticeLotSelected,
    bids,
    auctionNotice,
}) => {
    const { judgmentCriterion, decimalPlaces, rateTypeBid } = auctionNotice;
    const { t } = useTranslation();

    const handleDelete = async () => {
        try {
            await ApiService.auctioneerDeleteBid(
                auctionNoticeLotSelected.auctionNoticeId,
                bid.lotId,
                bid.id
            );
        } catch (error) {
            console.error(error)
        }
    }

    const itsSimpleCompany = () => {
        if (bid.itsSimpleCompany === 1) {
            return 'ME/EPP/COOP';
        }
        return '';
    };

    return (
        <Wrapped
            flexBox
            width='100%'
            padding='6px 5px'
            margin='3px 0'
            overflowX='hidden'
            overflowY='auto'
        >
            <Wrapper justifyContent='space-between' width='100%' alignItems='center' flexBox>
                <Wrapper alignItems='center' flexBox>
                    <Wrapper alignItems='center' flexBox>
                        <Wrapper
                            borderRadius='50%'
                            title={t('term.position')}
                            bgcolor='#dddddd'
                            width='26px'
                            flexBox
                            alignItems='center'
                            justifyContent='center'
                            color='#444'
                            fontSize='12px'
                            fontWeight='700'
                            height='26px'
                        >
                            {`${index < 9 ? `0${index + 1}` : `${index + 1}`}º`}
                        </Wrapper>
                        <Wrapper width='78px' margin='0 10px'>
                            <Wrapper fontSize='11px'>{moment(bid.createdAt)}</Wrapper>
                            <Wrapper fontWeight='700' fontSize='9px' color='var(--platform-secondary-color)'>
                                {itsSimpleCompany()}
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <BidInformation bids={bids} bid={bid}>
                        <Wrapper cursor='help' truncate fontSize='13px' margin=' 0 0 0 5px'>
                            {
                                formatValueWithAuctionRules(
                                    bid.value,
                                    judgmentCriterion,
                                    rateTypeBid,
                                    decimalPlaces
                                ).formatted
                            }
                        </Wrapper>
                    </BidInformation>
                </Wrapper>
                <Wrapper flexBox alignItems='center'>
                    {stagesCanDeleteBid.includes(auctionNoticeLotSelected.stage) && (
                        <Wrapper
                            cursor='pointer'
                            flexBox
                            title={t('info.delete.bid')}
                            justifyContent='center'
                            margin='0 5px 0 0'
                        >
                            <ConfirmationPopover
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>
                                            {t('info.bid.will.deleted', {
                                                value: formatValueWithAuctionRules(
                                                    bid.value,
                                                    judgmentCriterion,
                                                    rateTypeBid,
                                                    decimalPlaces
                                                ).formatted,
                                            })}
                                        </p>
                                        <p>{t('info.confirm.delete.bid')}</p>
                                    </Wrapper>
                                }
                                maxWidth='230px'
                                onConfirm={handleDelete}
                            >
                                <Image src='../../assets/icons/trash.svg' width='12px' height='13px' />
                            </ConfirmationPopover>
                        </Wrapper>
                    )}
                    {isDeclinedBid(bid) && (
                        <Badge
                            title={t('info.provider-declined')}
                            fontSize='9px'
                            color='#a47110'
                            width='24px'
                        >
                            {`DEC`}
                        </Badge>
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default BidItemAuctionner;
