import { useEffect, useState, useRef, FC } from 'react';
import { AuctionLotProps } from './props';
import AuctionLotItemAuctionner from '../AuctionLots/components/Auctioneer/AuctionLotItemAuctionner';
import AuctionLotItemSupplier from '../AuctionLots/components/Supplier/AuctionLotItemSupplier';
import { Bid } from '../../Interfaces/bid';
import { CloseBid } from '../../Interfaces/closeBids';
import ApiService from '../../services/api';
import { ProviderValue, ProviderValueStatus } from '../../Interfaces/providerValues';
import { methodDisputeDoAction } from '../../helpers/methodDispute';
import { StageLot } from '../../Interfaces/stageLot';
import moment from 'moment';
import { currMoment } from '../../helpers/moment';
import { chain, findLast, orderBy } from 'lodash';
import { AuctionNoticeLot, LotPosition } from '../../Interfaces/auctionNoticeLot';
import { SocketEvents, SocketEvent } from '../../Interfaces/socketEvents';
import { PauseLot } from '../../Interfaces/pauseLot';
import TinyQueue from 'tinyqueue';
import { percentDiff } from '../../helpers/difference';
import { CONSTANTS } from '../../constants';
import { timeout } from 'helpers/timer';
import { isAuctioneer, isCitizen, isProvider } from '../../helpers/permissions';
import AuctionLotItemCitzen from '../AuctionLots/components/Citzen/AuctionLotItemCitzen';
import { Decline, DeclineType } from '../../Interfaces/decline';
import { isDeclinedBid } from '../../helpers/declined';
import { MethodDisputeEnum } from 'enums/method-dispute.enum';
import { enableDefineCollocations } from 'helpers/enable-define-collocations.helper';
import { enableVerificationOfProposalCompliance } from 'helpers/enable-verification-of-proposal-compliance.helper';

export const stagesToIgnorePosition: string[] = [
    StageLot.negotiation,
    StageLot.negotiation_finished,
    StageLot.convoked,
];

const stagesToClearBestBid: string[] = [
    StageLot.close_period,
    StageLot.second_close_period,
    StageLot.repeat_second_close_period,
];

export const stagesToGetCloseBids = [
    StageLot.close_period_ended,
    StageLot.second_close_period_ended,
    StageLot.repeat_second_close_period_ended,
];

export const stagesToGetBestCloseBids = [
    StageLot.close_period,
    StageLot.close_period_ended,
    StageLot.second_close_period,
    StageLot.second_close_period_ended,
    StageLot.repeat_second_close_period,
    StageLot.repeat_second_close_period_ended,
];
type EventHandlers = {
    [key in keyof typeof SocketEvents]: { handler: (...args: any) => any };
};

const AuctionLot: FC<AuctionLotProps> = (props: AuctionLotProps) => {
    const {
        authUser,
        auctionLot,
        auctionNotice,
        serverTimestamp,
        socketConnection,
        onUpdateSelectedLot,
        onSelectAuctionLot,
        removeLotFromView,
    } = props;

    const queueEvents: any = new TinyQueue([]);
    let queueLock = false;

    const [bids, setBids] = useState<Bid[]>([]);
    const [closeBids, setCloseBids] = useState<CloseBid[]>([]);
    const [providerValues, setProviderValues] = useState<ProviderValue[]>([]);
    const [auctionNoticeLot, setActionNoticeLot] = useState<AuctionNoticeLot>(auctionLot);
    const isRandomPeriodWithDisputeOpen = [
        MethodDisputeEnum.open,
        MethodDisputeEnum.closedOpen,
    ].includes(auctionNotice.methodDispute);
    const isRandomPeriodWithDisputeClosed = [
        MethodDisputeEnum.openClosed,
        MethodDisputeEnum.closed,
    ].includes(auctionNotice.methodDispute);

    const visibleCloseBids =
        (auctionNotice.methodDispute === MethodDisputeEnum.openClosed ||
            auctionNotice.methodDispute === MethodDisputeEnum.closed) &&
        stagesToGetBestCloseBids.includes(auctionLot.stage);

    const getAndSetExtensionTimeToken = () => {
        if (
            (auctionLot.stage === StageLot.random_period ||
                auctionLot.stage === StageLot.random_close_period) &&
            isRandomPeriodWithDisputeClosed
        ) {
            changeStage(StageLot.random_close_period);
            return;
        }
        if (auctionLot.stage === StageLot.random_period && isRandomPeriodWithDisputeOpen) {
            changeStage(StageLot.random_period);
            return;
        }
    };

    useEffect(() => {
        getAndSetExtensionTimeToken();
    }, [auctionLot.stage]);

    const bidsRef: any = useRef(null);
    bidsRef.current = { bids, setBids };

    const closeBidsRef: any = useRef(null);
    closeBidsRef.current = { closeBids, setCloseBids };

    const providerValuesRef: any = useRef(null);
    providerValuesRef.current = { providerValues, setProviderValues };

    const auctionNoticeLotRef: any = useRef(null);
    auctionNoticeLotRef.current = { auctionNoticeLot, setActionNoticeLot };

    const authUserRef: any = useRef(null);
    authUserRef.current = { authUser };

    useEffect(() => {
        if (
            (auctionLot.stage === StageLot.random_period ||
                auctionLot.stage === StageLot.random_close_period) &&
            auctionLot.extensionTimeIsExpired &&
            auctionNotice.methodDispute === 2
        ) {
            changeStage(StageLot.random_close_period_ended);
        }
        if (
            (auctionLot.stage === StageLot.random_period ||
                auctionLot.stage === StageLot.random_close_period) &&
            auctionLot.extensionTimeIsExpired &&
            auctionNotice.methodDispute === 1
        ) {
            changeStage(StageLot.random_period_ended);
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!socketConnection) {
            handleSocketEvents();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const getData = async () => {
        await Promise.all([await getBids(), await getProviderValues(), await getCloseBids()]);
        doMethodDisputeActions();
    };

    const getProviderValues = async () => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;
        const stages = [
            StageLot.awaiting_call_provider,
            StageLot.negotiation,
            StageLot.negotiation_finished,
            StageLot.awaiting_rejudge,
        ];

        if (
            stages.includes(auctionNoticeLot.status) ||
            auctionLot.tiebreakerRound ||
            enableDefineCollocations(auctionNotice.auctionTypeRules, auctionLot.hasWinner) ||
            (enableVerificationOfProposalCompliance(auctionNotice.auctionTypeRules) &&
                auctionLot.stage === StageLot.verification_of_proposal_compliance)
        ) {
            const providerValueList = await ApiService.getProviderValues(
                auctionNoticeLot.auctionNoticeId,
                auctionNoticeLot.id
            );
            providerValuesRef.current.setProviderValues(providerValueList);
        }
    };

    const getBids = async () => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        const bidList = await ApiService.getBids(
            auctionNoticeLot.auctionNoticeId,
            auctionNoticeLot.id
        );

        bidsRef.current.setBids(bidList);
        calculatePositionFromBids(bidList);
    };

    const calculatePositionFromBids = (bids: Bid[]) => {
        if (stagesToIgnorePosition.includes(auctionLot.stage) || visibleCloseBids) {
            return;
        }

        const position = calculateLotPosition(auctionNotice.judgmentCriterion, bids);
        handleUpdatePosition(position);
    };

    const getCloseBids = async () => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;
        if (visibleCloseBids) {
            const closeBids = await ApiService.getCloseBids(
                auctionNoticeLot.auctionNoticeId,
                auctionNoticeLot.id
            );
            closeBidsRef.current.setCloseBids(closeBids);
        }
    };

    const doMethodDisputeActions = () => {
        const action = methodDisputeDoAction({
            methodDispute: auctionNotice.methodDispute,
            open: () => {
                checkExistCalledProvider();
                checkExistWinnerNegotiation();
            },
            closed: () => {
                checkExistCalledProvider();
                checkExistWinnerNegotiation();
            },
        });

        return action?.();
    };

    const checkExistCalledProvider = () => {
        const { providerValues } = providerValuesRef.current;

        if (providerValues?.length === 0) {
            return;
        }

        const calledProvider: ProviderValue = providerValues.find(
            (currProviderValue: ProviderValue) =>
                currProviderValue.status === ProviderValueStatus.called
        );

        if (!calledProvider) {
            return;
        }

        const now = currMoment(serverTimestamp.difference);
        const finish = moment(calledProvider.statusUpdatedAt).add(
            CONSTANTS.timersDuration.CONVOKED_PERIOD,
            'seconds'
        );

        // verifica se está dentro do periodo de 3 minutos
        if (moment.duration(finish.diff(now)).asSeconds() > 0) {
            return handleCalledProvider(calledProvider);
        }
    };

    const handleCalledProvider = (providerValue: ProviderValue) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (providerValue?.lotId !== auctionNoticeLot.id) {
            return;
        }

        const providerValuesDeep = [...providerValuesRef.current.providerValues];

        if (
            providerValue.providerAuctionCode === authUser.providerAuctionCode ||
            !isProvider(authUser)
        ) {
            interceptSetActionNoticeLot(
                {
                    ...auctionNoticeLot,
                    convokedProvider: providerValue,
                    stage: StageLot.convoked,
                },
                true
            );
        }

        providerValuesRef.current.setProviderValues(
            providerValuesDeep.filter((curr: ProviderValue) => curr.id !== providerValue.id)
        );
    };

    const handleProviderDisabled = (lot: AuctionNoticeLot) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (lot.id !== auctionNoticeLot.id) {
            return;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...lot,
            },
            true
        );
    };

    const handleWinnerProviderValues = (providerValue: ProviderValue) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (providerValue.lotId !== auctionNoticeLot.id) {
            return;
        }

        const providerValuesDeep = [...providerValuesRef.current.providerValues];

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                convokedProvider: undefined,
                stage: StageLot.negotiation,
                winnerProvider: providerValue,
            },
            true
        );

        providerValuesRef.current.setProviderValues([
            ...providerValuesDeep.filter((curr: ProviderValue) => curr.id !== providerValue.id),
            providerValue,
        ]);
    };

    const checkExistWinnerNegotiation = () => {
        const { providerValues, setProviderValues } = providerValuesRef.current;

        if (providerValues?.length === 0) {
            return;
        }

        const existWinnerProviders: ProviderValue[] = providerValues.filter(
            (currProviderValue: ProviderValue) =>
                currProviderValue.status === ProviderValueStatus.winner
        );

        if (existWinnerProviders?.length === 0) {
            return;
        }

        const providerValuesDeep = [...providerValues];

        existWinnerProviders.forEach((providerValue: ProviderValue) => {
            interceptSetActionNoticeLot(
                {
                    ...auctionNoticeLotRef.current.auctionNoticeLot,
                    winnerProvider: providerValue,
                },
                true
            );
        });
        setProviderValues([...providerValuesDeep]);
    };

    const getSocketHandlers = () =>
        ({
            [SocketEvents.lotStarted]: {
                handler: handleStartLot,
            },
            [SocketEvents.defineCollocations]: {
                handler: handleDefineCollocations,
            },
            [SocketEvents.lotTiedStarted]: {
                handler: handleStartTiedLot,
            },
            [SocketEvents.lotPaused]: {
                handler: handlePauseStatus,
            },
            [SocketEvents.lotUnPaused]: {
                handler: handlePauseStatus,
            },
            [SocketEvents.lotUpdated]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.lotCanceled]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.lotNegotiationFinished]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.lotFinished]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.lotRenewed]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.newBid]: {
                handler: handleNewBid,
            },
            [SocketEvents.deletedBid]: {
                handler: handleDeletedBid,
            },
            [SocketEvents.providerValueWinner]: {
                handler: handleWinnerProviderValues,
            },
            [SocketEvents.providerValueUpdated]: {
                handler: handleProviderValueUpdated,
            },
            [SocketEvents.providerValueReseted]: {
                handler: handleProviderValueUpdated,
            },
            [SocketEvents.providerValuesCreated]: {
                handler: handleProviderValues,
            },
            [SocketEvents.declineCoverLot]: {
                handler: handleDeclineCoverLot,
            },
            [SocketEvents.providerValuesUpdated]: {
                handler: handleProviderValues,
            },
            [SocketEvents.secondCloseBidsCreated]: {
                handler: (
                    event: {
                        lot: AuctionNoticeLot;
                        closeBids: CloseBid[];
                    },
                    socketType: SocketEvents
                ) => handleCreatedCloseBids(event.lot, event.closeBids, socketType),
            },
            [SocketEvents.closeBidsCreated]: {
                handler: (
                    event: {
                        lot: AuctionNoticeLot;
                        closeBids: CloseBid[];
                    },
                    socketType: SocketEvents
                ) => handleCreatedCloseBids(event.lot, event.closeBids, socketType),
            },
            [SocketEvents.closeBidUpdated]: {
                handler: handleCloseBidUpdated,
            },
            [SocketEvents.providerValueCalled]: {
                handler: handleCalledProvider,
            },
            [SocketEvents.providerDisabled]: {
                handler: handleProviderDisabled,
            },
            [SocketEvents.lotReopenNegotiation]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.classifiedProposalLot]: {
                handler: handleClassifiedProposalLot,
            },
            [SocketEvents.lotAwaitingRejudge]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.lotAwaitingCallProvider]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.lotNoWinnerFinished]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.awaitingRepeatCloseBids]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.randomPeriodStarted]: {
                handler: handleUpdateLot,
            },
            [SocketEvents.randomPeriodEnded]: {
                handler: setRandomPeriodEnded,
            },
            [SocketEvents.deferredCreated]: {
                handler: handleDeferredCreated,
            },
            [SocketEvents.declineCreated]: {
                handler: handleDeclineCreated,
            },
            [SocketEvents.repeatCloseBidsCreated]: {
                handler: (
                    event: {
                        lot: AuctionNoticeLot;
                        closeBids: CloseBid[];
                    },
                    socketType: SocketEvents
                ) => handleCreatedRepeatCloseBids(event.lot, event.closeBids, socketType),
            },
        } as EventHandlers);

    const handleSocketEvents = () => {
        const socketHandlers = getSocketHandlers();

        Object.keys(socketHandlers).forEach((key: string) => {
            socketConnection.on(key, (event: any) =>
                receiveEvent({
                    type: key as SocketEvents,
                    message: event,
                })
            );
        });
    };

    const handleDeclineCreated = (decline: Decline) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (auctionNoticeLot.id !== decline.lotId) {
            return;
        }
    };

    const handleDeferredCreated = async (decline: Decline) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        // se não tem decline.lotId, atualiza em todos os lotes
        if ((!!decline.lotId && auctionNoticeLot.id === decline.lotId) || !decline.lotId) {
            const { authUser } = authUserRef.current;

            const currBids = [
                ...bidsRef.current.bids.filter(
                    (currBid: Bid) =>
                        currBid.providerAuctionCode !== decline.participate?.providerAuctionCode
                ),
            ];
            const { judgmentCriterion } = auctionNotice;

            const orderedBids = orderBy(
                [...currBids],
                'value',
                judgmentCriterion === 2 || judgmentCriterion === 3 ? 'desc' : 'asc'
            );

            let newBestBid = orderedBids?.[0];
            let newProviderBestBid: Bid | null = orderedBids.find(
                (bid) => bid.providerAuctionCode === decline.participate?.providerAuctionCode
            );

            const lot = {
                ...auctionNoticeLot,
            };

            if (orderedBids.length && newBestBid?.value) {
                lot.bestBid = newBestBid.value;
            }

            try {
                if (!orderedBids?.length) {
                    if (!isProvider(authUser)) {
                        const bestBid = await getBestBid();
                        newBestBid = bestBid;
                    } else {
                        const bestBid = await getBestBidFromProvider();
                        const providerBestBid = await getProviderBestBidFromProvider();
                        newBestBid = bestBid;
                        newProviderBestBid = providerBestBid;
                    }
                }

                if (isProvider(authUser)) {
                    if (!newProviderBestBid?.value) {
                        const providerBestBid = await getProviderBestBidFromProvider();
                        newProviderBestBid = providerBestBid;
                    }
                }
            } catch (error) {}

            if (newBestBid) {
                lot.bestBid = newBestBid?.value ?? null;
            }

            if (authUser.providerAuctionCode === decline.participate?.providerAuctionCode) {
                interceptSetActionNoticeLot(
                    {
                        ...lot,
                        ...(decline.type === DeclineType.definitive
                            ? {
                                  definiveDecline: decline,
                              }
                            : {
                                  temporaryDecline: decline,
                              }),
                        providerBestBid: newProviderBestBid?.value ?? null,
                        additionalExpense: newProviderBestBid?.additionalExpense ?? null,
                    },
                    true
                );
            } else {
                interceptSetActionNoticeLot(lot, true);
            }

            const newBids = [
                ...bidsRef.current.bids.filter(
                    (bid) => bid.providerAuctionCode !== decline.participate?.providerAuctionCode
                ),
            ];

            const newProviderValues = [
                ...providerValuesRef.current.providerValues.filter(
                    (providerValue) =>
                        providerValue.providerAuctionCode !==
                        decline.participate?.providerAuctionCode
                ),
            ];

            bidsRef.current.setBids(newBids);
            providerValuesRef.current.setProviderValues(newProviderValues);
            recalculeLotPosition(newBids);
        }
    };

    const handleClassifiedProposalLot = async ({
        lot,
        providerAuctionCode,
    }: {
        lot: AuctionNoticeLot;
        providerAuctionCode: number;
    }) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;
        if (lot.id !== auctionNoticeLot.id) {
            return;
        }

        const { authUser } = authUserRef.current;

        let newBestBid: any = null;
        let newProviderBestBid: any = null;

        if (!isProvider(authUser)) {
            const bestBid = await getBestBid();
            newBestBid = bestBid;
        } else {
            const bestBid = await getBestBidFromProvider();
            const providerBestBid = await getProviderBestBidFromProvider();
            newBestBid = bestBid;
            newProviderBestBid = providerBestBid;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                bestBid: newBestBid?.value ?? null,
                providerBestBid:
                    newProviderBestBid?.value ??
                    (authUser.providerAuctionCode === providerAuctionCode
                        ? newBestBid?.value ?? null
                        : auctionNoticeLot.providerBestBid),
            },
            false
        );
    };

    const handleProviderValues = async (providerValues: ProviderValue[]) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (providerValues[0]?.lotId !== auctionNoticeLot.id) {
            return;
        }

        if (
            auctionNoticeLot.stage === StageLot.negotiation_finished &&
            auctionNoticeLot.allowMultipleWinner
        ) {
            return;
        }

        const { judgmentCriterion } = auctionNotice;

        const groupedByProvider = orderBy(
            chain(providerValues)
                .filter(
                    (providerValue) => providerValue.status !== ProviderValueStatus.winner_disabled
                )
                .groupBy((providerValue) => providerValue.providerAuctionCode)
                .map((value: any) => ({ ...findLast(value, (providerValue) => providerValue) }))
                .value(),
            'value',
            judgmentCriterion === 2 || judgmentCriterion === 3 ? 'desc' : 'asc'
        );

        const providerBestBid = groupedByProvider.find(
            (provider) => provider.providerAuctionCode === authUser.providerAuctionCode
        );

        const convokedProvider = auctionNoticeLot.convokedProvider;

        const isCalledConvokedProvider =
            providerValues.find((providerValue) => providerValue.id === convokedProvider?.id)
                ?.status === ProviderValueStatus.called;

        const lotUpdate = {
            ...auctionNoticeLot,
            bestBid: groupedByProvider[0]?.value ?? null,
            providerBestBid: providerBestBid?.value ?? null,
            additionalExpense: providerBestBid?.additionalExpense ?? null,
        };

        if (!isCalledConvokedProvider) {
            lotUpdate.convokedProvider = null;
        }

        if (
            auctionNoticeLot.stage !== StageLot.awaiting_repeat_close_period &&
            auctionNoticeLot.stage !== StageLot.convoked
        ) {
            lotUpdate.stage = StageLot.awaiting_call_provider;
        }

        interceptSetActionNoticeLot(lotUpdate, true);
        providerValuesRef.current.setProviderValues(providerValues);
    };

    const handleDeclineCoverLot = async (providerValues: ProviderValue[]) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (providerValues[0]?.lotId !== auctionNoticeLot.id) {
            return;
        }

        if (
            auctionNoticeLot.stage === StageLot.negotiation_finished &&
            auctionNoticeLot.allowMultipleWinner
        ) {
            return;
        }
        handleProviderValues(providerValues);
        changeStage(StageLot.awaiting_call_provider);
    };

    const handleStartLot = (auctionLot: AuctionNoticeLot) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (auctionLot.id !== auctionNoticeLot.id) {
            return;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...auctionLot,
                stage: StageLot.started,
            },
            true
        );
    };

    const handleDefineCollocations = (data: {
        lot: AuctionNoticeLot;
        providerValue: ProviderValue;
    }) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (data.lot.id !== auctionNoticeLot.id) {
            return;
        }

        const providerValuesDeep = [...providerValuesRef.current.providerValues];
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...data.lot,
                stage: StageLot.started,
                winnerProvider: data.providerValue,
            },
            true
        );
        providerValuesRef.current.setProviderValues([
            ...providerValuesDeep.filter(
                (curr: ProviderValue) => curr.id !== data.providerValue.id
            ),
            data.providerValue,
        ]);
    };

    const handleStartTiedLot = ([lot, providerValues]: [AuctionNoticeLot, ProviderValue[]]) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (lot.id !== auctionNoticeLot.id) {
            return;
        }
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...lot,
                stage: StageLot.started,
            },
            true
        );
        providerValuesRef.current.setProviderValues(providerValues);
    };

    const handlePauseStatus = ({ lotId, pauses }: { lotId: string; pauses: PauseLot[] }) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (parseInt(lotId) !== auctionNoticeLot.id) {
            return;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                pauses,
                stage: !!pauses[pauses.length - 1]?.pauseEnd ? StageLot.started : StageLot.paused,
            },
            false
        );
    };

    const interceptSetActionNoticeLot = (auctionLot: AuctionNoticeLot, updateSelected: boolean) => {
        if (stagesToIgnorePosition.includes(auctionLot.stage)) {
            auctionLot = {
                ...auctionLot,
                position: undefined,
            };
        }

        if (stagesToClearBestBid.includes(auctionLot.stage)) {
            auctionLot = {
                ...auctionLot,
                bestBid: null,
            };
        }

        auctionNoticeLotRef.current.setActionNoticeLot(auctionLot);

        if (updateSelected) {
            return onUpdateSelectedLot(auctionLot);
        }
    };

    const handleUpdateLot = (auctionLot: AuctionNoticeLot) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (auctionLot.id !== auctionNoticeLot.id) {
            return;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...auctionLot,
                stage: auctionLot.status,
            },
            true
        );

        if (auctionLot.status === StageLot.negotiation_finished) {
            removeLotFromView(auctionNoticeLot.id);
        }
    };

    const setRandomPeriodEnded = (lot: AuctionNoticeLot) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;
        if (lot.id !== auctionNoticeLot.id) {
            return;
        }
        if (isRandomPeriodWithDisputeClosed) {
            changeStage(StageLot.random_close_period_ended);
        }
        if (isRandomPeriodWithDisputeOpen) {
            changeStage(StageLot.random_period_ended);
        }
    };

    const updateWithCriterion = (currValue: string, oldValue: string | null) => {
        if (oldValue === null || !oldValue) {
            return currValue;
        }
        const { judgmentCriterion } = auctionNotice;

        const currValueCtx = parseFloat(currValue);
        const oldValueCtx = parseFloat(oldValue);

        if (judgmentCriterion === 2 || judgmentCriterion === 3) {
            return currValueCtx > oldValueCtx ? currValue : oldValue;
        }
        return currValueCtx < oldValueCtx ? currValue : oldValue;
    };

    const onProviderBestBidLoaded = ({ value, additionalExpense, proposal }: Bid) =>
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLotRef.current.auctionNoticeLot,
                providerBestBid: value,
                additionalExpense: additionalExpense ?? proposal?.additionalExpense ?? null,
            },
            false
        );

    const onBestBidLoaded = ({ value, additionalExpense, proposal }: Bid) =>
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLotRef.current.auctionNoticeLot,
                bestBid: value,
                additionalExpense: additionalExpense ?? proposal?.additionalExpense ?? null,
            },
            false
        );

    const changeStage = (stage: StageLot) => {
        if (auctionNoticeLotRef.current.auctionNoticeLot?.stage === stage) {
            return;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLotRef.current.auctionNoticeLot,
                stage,
            },
            true
        );
    };

    const handleUpdatePosition = (position: any) =>
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLotRef.current.auctionNoticeLot,
                position,
            },
            false
        );

    const handleNewBid = (bid: Bid) => {
        const { lotId, providerAuctionCode } = bid;
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (auctionNoticeLot.id !== lotId) {
            return;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...bid.lot,
                bestBid: updateWithCriterion(bid.value, auctionNoticeLot.bestBid),
                providerBestBid:
                    authUser.providerAuctionCode === providerAuctionCode
                        ? updateWithCriterion(bid.value, auctionNoticeLot.providerBestBid)
                        : auctionNoticeLot.providerBestBid,
            },
            false
        );

        const newBids = orderBy([...bidsRef.current.bids, bid], 'value', 'asc');
        bidsRef.current.setBids(newBids);
        recalculeLotPosition(newBids);
    };

    const onFinishProviderPeriodConvoked = () =>
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLotRef.current.auctionNoticeLot,
                convokedProvider: undefined,
                stage: StageLot.awaiting_call_provider,
            },
            true
        );

    const getProviderBestBidFromLocalBids = (bids: Bid[]) => {
        const providerBestBid = bids.find(
            (bid) => bid.providerAuctionCode === authUser.providerAuctionCode
        );

        return providerBestBid?.value ?? null;
    };

    const handleDeletedBid = async (bid: Bid) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (bid.lotId !== auctionNoticeLot.id) {
            return;
        }

        const currBids = [...bidsRef.current.bids.filter((currBid: Bid) => currBid.id !== bid.id)];
        const { judgmentCriterion } = auctionNotice;

        const orderedBids = orderBy(
            currBids,
            'value',
            judgmentCriterion === 2 || judgmentCriterion === 3 ? 'desc' : 'asc'
        );

        let newBestBid = orderedBids?.[0];
        let newProviderBestBid: Bid | null = null;

        // se chegar um novo bid enquanto pega os dados da proposta da api
        // a propria rota ja vai retornar o melhor valor
        if (!newBestBid) {
            if (!isProvider(authUser)) {
                const bestBid = await getBestBid();
                newBestBid = bestBid;
            } else {
                const bestBid = await getBestBidFromProvider();
                const providerBestBid = await getProviderBestBidFromProvider();
                newBestBid = bestBid;
                newProviderBestBid = providerBestBid;
            }
        }

        let newProviderBestBidValue: string | null = null;

        if (isProvider(authUser)) {
            if (!newProviderBestBid?.value) {
                // se é o mesmo fornecedor então teve alguma alteração no valor, pois foi deletado o lance
                if (authUser.providerAuctionCode === bid.providerAuctionCode) {
                    newProviderBestBidValue = getProviderBestBidFromLocalBids(orderedBids);
                } else {
                    // aqui o fornecedor não teve nada deletado então o melhor dele é o que estava antes
                    newProviderBestBidValue = auctionNoticeLot.providerBestBid;
                }
            }

            // se não tem tem nenhum valor aqui então não tem nenhum lance do fornecedor para buscar o anterior
            // então provavelmente é a proposta do fornecedor
            if (!newProviderBestBidValue || !newProviderBestBid?.value) {
                const providerBestBid = await getProviderBestBidFromProvider();
                newProviderBestBidValue = providerBestBid?.value;
            }
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                bestBid: newBestBid?.value ?? null,
                providerBestBid: newProviderBestBidValue ?? newProviderBestBid?.value,
                additionalExpense: newProviderBestBid?.additionalExpense ?? null,
            },
            false
        );

        bidsRef.current.setBids(orderedBids);
        recalculeLotPosition(orderedBids);
    };

    const calculateLotPosition = (judgmentCriterion: number, list: Bid[]) => {
        const bidsGroup = orderBy(
            chain(
                orderBy(
                    list,
                    'value',
                    judgmentCriterion === 2 || judgmentCriterion === 3 ? 'asc' : 'desc'
                )
            )
                .groupBy((bid: Bid | ProviderValue) => bid.providerAuctionCode)
                .map((value: any) => ({ ...findLast(value, (bid) => bid) }))
                .value(),
            'value',
            judgmentCriterion === 2 || judgmentCriterion === 3 ? 'desc' : 'asc'
        );

        const position = bidsGroup.findIndex(
            (bid) => bid.providerAuctionCode === authUser.providerAuctionCode
        );

        let positionData = {
            position: position >= 0 ? position + 1 : 0,
            percent: undefined,
            uniqueBids: bidsGroup.length,
        } as LotPosition;

        // se só tem um lance no lote não tem percent, pois só exibe porcentagem
        // quando tem mais de um fornecedor deu lance
        if (bidsGroup.length > 1) {
            if (judgmentCriterion === 3 || judgmentCriterion === 2) {
                if (positionData.position === 1) {
                    positionData.percent = percentDiff(
                        bidsGroup[1].value,
                        bidsGroup[0].value,
                        auctionNotice.judgmentCriterion,
                        true
                    );
                } else {
                    positionData.percent = percentDiff(
                        bidsGroup[0].value,
                        bidsGroup[1].value,
                        auctionNotice.judgmentCriterion,
                        true
                    );
                }
            } else {
                if (positionData.position === 1) {
                    positionData.percent = percentDiff(
                        bidsGroup[0].value,
                        bidsGroup[1].value,
                        auctionNotice.judgmentCriterion,
                        true
                    );
                } else {
                    positionData.percent = percentDiff(
                        bidsGroup[1].value,
                        bidsGroup[0].value,
                        auctionNotice.judgmentCriterion,
                        true
                    );
                }
            }
        }

        return positionData;
    };

    const recalculeLotPosition = (bids: Bid[]) => {
        // não utiliza lances de fornecedores q declinaram
        const position = calculateLotPosition(
            auctionNotice.judgmentCriterion,
            bids.filter((bid) => !isDeclinedBid(bid))
        );

        handleUpdatePosition(position);
    };

    const handleProviderValueUpdated = (providerValue: ProviderValue) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;

        if (providerValue.lotId !== auctionNoticeLot.id) {
            return;
        }

        const { providerValues = [] } = providerValuesRef.current;
        const providerIndex = providerValues.findIndex(
            (currProviderValue: ProviderValue) => providerValue.id === currProviderValue.id
        );

        providerValues[providerIndex] = {
            ...providerValues[providerIndex],
            ...providerValue,
            value: providerValue.value,
        };

        providerValuesRef.current.setProviderValues(providerValues);

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                bestBid: providerValue.value,
                providerBestBid:
                    authUser?.providerAuctionCode ===
                    providerValues[providerIndex]?.providerAuctionCode
                        ? providerValue.value
                        : auctionNoticeLot.providerBestBid,
            },
            true
        );
    };

    const handleCreatedCloseBids = (
        lot: AuctionNoticeLot,
        closeBidsList: CloseBid[],
        socketType: SocketEvents
    ) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;
        if (lot.id !== auctionNoticeLot.id) {
            return;
        }

        const providerBestCloseBid = closeBidsList.find(
            (closeBid) => closeBid.providerAuctionCode === authUser.providerAuctionCode
        );

        closeBidsRef.current.setCloseBids([...closeBidsList]);
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...lot,
                bestBid: null,
                providerBestBid: String(providerBestCloseBid?.value) ?? null,
                position: null,
                stage:
                    socketType === SocketEvents.closeBidsCreated
                        ? StageLot.close_period
                        : StageLot.second_close_period,
            },
            true
        );
    };

    const handleCreatedRepeatCloseBids = (
        lot: AuctionNoticeLot,
        closeBidsList: CloseBid[],
        socketType: SocketEvents
    ) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;
        if (lot.id !== auctionNoticeLot.id) {
            return;
        }

        const providerBestCloseBid = closeBidsList.find(
            (closeBid) => closeBid.providerAuctionCode === authUser.providerAuctionCode
        );

        closeBidsRef.current.setCloseBids([...closeBidsList]);
        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLot,
                ...lot,
                bestBid: null,
                providerBestBid: String(providerBestCloseBid?.value) ?? null,
                position: null,
                stage: StageLot.repeat_second_close_period,
            },
            true
        );
    };

    const handleCloseBidUpdated = (closeBid: CloseBid) => {
        const { auctionNoticeLot } = auctionNoticeLotRef.current;
        if (closeBid.lotId !== auctionNoticeLot.id) {
            return;
        }

        const { closeBids } = closeBidsRef.current;
        const closeBidIndex = closeBids.findIndex(
            (currCloseBid: CloseBid) => closeBid.id === currCloseBid.id
        );

        closeBids[closeBidIndex] = {
            ...closeBids[closeBidIndex],
            ...closeBid,
        };

        closeBidsRef.current.setCloseBids([...closeBids]);

        if (closeBid.providerAuctionCode !== authUser.providerAuctionCode) {
            return;
        }

        interceptSetActionNoticeLot(
            {
                ...auctionNoticeLotRef.current.auctionNoticeLot,
                bestBid: null,
                providerBestBid: String(closeBid.value) ?? null,
            },
            false
        );
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const socketHandlers = getSocketHandlers();

                const { type, message } = event;
                socketHandlers[type].handler?.(message, type);

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };

    const getBestBid = async () => {
        const bestBid = await ApiService.getBestBid(auctionLot.auctionNoticeId, auctionLot.id);

        if (!bestBid) {
            return {
                id: null,
                value: null,
                lotId: auctionLot.id,
            };
        }

        return bestBid;
    };

    const getProviderBestBid = async () => {
        const providerBestBid = await ApiService.getProviderBestBid(
            auctionLot.auctionNoticeId,
            auctionLot.id
        );

        if (!providerBestBid) {
            return {
                id: null,
                value: null,
                lotId: auctionLot.id,
            };
        }

        return providerBestBid;
    };

    const getProviderBestCloseBid = async () => {
        if (stagesToGetBestCloseBids.includes(auctionLot.stage)) {
            return await ApiService.getProviderBestCloseBid(
                auctionLot.auctionNoticeId,
                auctionLot.id
            );
        }

        return getProviderBestBid();
    };

    const getProviderBestBidFromProvider = async () => {
        const action = methodDisputeDoAction({
            methodDispute: auctionNotice.methodDispute,
            open: () => getProviderBestBid(),
            closed: () => getProviderBestCloseBid(),
        });

        return await action?.();
    };

    const getBestBidFromProvider = async () => {
        const action = methodDisputeDoAction({
            methodDispute: auctionNotice.methodDispute,
            open: () => getBestBid(),
            closed: () => {
                if (stagesToGetCloseBids.includes(auctionLot.stage)) {
                    return;
                }
                return getBestBid();
            },
        });

        return await action?.();
    };

    if (isAuctioneer(authUser)) {
        return (
            <AuctionLotItemAuctionner
                {...props}
                key={`auctionNoticeLot:${auctionNoticeLotRef.current.auctionNoticeLot.id}`}
                auctionLot={auctionNoticeLotRef.current.auctionNoticeLot}
                onBestBidLoaded={onBestBidLoaded}
                changeStage={changeStage}
                onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                closeBids={closeBidsRef.current.closeBids ?? []}
                providerValues={providerValuesRef.current.providerValues ?? []}
                onSelectAuctionLot={onSelectAuctionLot}
            />
        );
    }

    if (isProvider(authUser) && !auctionLot?.isUnclassified) {
        return (
            <AuctionLotItemSupplier
                {...props}
                key={`auctionNoticeLot:${auctionNoticeLotRef.current.auctionNoticeLot.id}`}
                auctionLot={auctionNoticeLotRef.current.auctionNoticeLot}
                onBestBidLoaded={onBestBidLoaded}
                changeStage={changeStage}
                onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                onProviderBestBidLoaded={onProviderBestBidLoaded}
                onSelectAuctionLot={onSelectAuctionLot}
                closeBids={closeBidsRef.current.closeBids}
                providerValues={providerValuesRef.current.providerValues}
            />
        );
    }

    if (isCitizen(authUser) || auctionLot?.isUnclassified) {
        return (
            <AuctionLotItemCitzen
                {...props}
                key={`auctionNoticeLot:${auctionNoticeLotRef.current.auctionNoticeLot.id}`}
                auctionLot={auctionNoticeLotRef.current.auctionNoticeLot}
                onBestBidLoaded={onBestBidLoaded}
                changeStage={changeStage}
                onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                onSelectAuctionLot={onSelectAuctionLot}
            />
        );
    }

    return <></>;
};

export default AuctionLot;
