import { FC } from 'react';
import { LotListAreaHeaderProps } from './props';
import Wrapper from '../Common/wrapper';
import CardLotMode from '../CardLotMode';
import ReactDOM from 'react-dom';
import { Radio, Switch } from 'antd';
import { LotsTab } from 'Components/LotListArea';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../helpers/mobile';
import FiltersModal from './components/filters-modal';
import ModalDeclines from '../ModalDeclines';
import { TypeAccess } from '../../Interfaces/user';
import { useLotsContext } from '../../pages/Initial/select-lots.context';
import CardOnlineProviders from '../CardOnlineProviders';
import ModalMultipleWinnerCover from '../ModalMultipleWinnerCover';
import { isAuctioneer, isProvider } from '../../helpers/permissions';
import ModalVerificationOfProposalCompliance from 'Components/ModalVerificationOfProposalCompliance';
import { enableVerificationOfProposalCompliance } from 'helpers/enable-verification-of-proposal-compliance.helper';

const LotListAreaHeader: FC<LotListAreaHeaderProps> = ({
    authUser,
    auctionNotice,
    pagination,
    handlePaginateTabChanged,
    socketConnection,
}) => {
    const { t } = useTranslation();
    const { setBulkChangesMode, setSelectedLots, setSelectedMode, bulkChangesMode } =
        useLotsContext();

    const getSelectedTabStyle = (tab: LotsTab) => {
        return {
            ...(pagination.tab === tab
                ? {
                      background: 'var(--platform-primary-color)',
                      color: '#FFF',
                  }
                : {}),
        };
    };

    const handleChangeBulkChanges = (value: boolean) => {
        if (!value) {
            ReactDOM.unstable_batchedUpdates(() => {
                setSelectedLots([]);
                setBulkChangesMode(false);
                setSelectedMode(undefined);
            });
            return;
        }

        ReactDOM.unstable_batchedUpdates(() => {
            setSelectedLots([]);
            setBulkChangesMode(true);
            setSelectedMode(undefined);
        });
    };

    const enableDeclines =
        !auctionNotice?.auctionTypeRules?.generalSettings?.cadastro?.desativarOpcaoDeclinio &&
        authUser.typeAccess === TypeAccess.organization;
    const showModalVerificationOfProposalCompliance =
        enableVerificationOfProposalCompliance(auctionNotice?.auctionTypeRules) &&
        (isProvider(authUser) || isAuctioneer(authUser));

    return (
        <Wrapper borderRight='1px #d7d7d7 solid' padding='2px'>
            <Wrapper
                position='relative'
                padding='12px 0'
                flexBox
                justifyContent='space-evenly'
                alignItems='center'
            >
                <CardLotMode authUser={authUser} auctionNotice={auctionNotice} />
                {enableDeclines && (
                    <ModalDeclines
                        auctionId={auctionNotice.id}
                        socketConnection={socketConnection}
                    />
                )}
                {isProvider(authUser) ? (
                    <ModalMultipleWinnerCover
                        auction={auctionNotice}
                        socketConnection={socketConnection}
                    />
                ) : null}
                {showModalVerificationOfProposalCompliance && (
                    <ModalVerificationOfProposalCompliance
                        process={auctionNotice}
                        socketConnection={socketConnection}
                        authUser={authUser}
                    />
                )}
                {authUser.typeAccess === TypeAccess.organization && <CardOnlineProviders />}
            </Wrapper>
            <Wrapper
                display='flex'
                justifyContent={
                    authUser?.typeAccess === TypeAccess.organization ? 'space-between' : 'flex-end'
                }
                padding='0 10px 5px 10px'
            >
                {authUser.typeAccess === TypeAccess.organization && (
                    <Wrapper display='flex' alignItems='center'>
                        <Switch checked={bulkChangesMode} onChange={handleChangeBulkChanges} />
                        <span style={{ margin: '0 0 0 8px' }}>
                            <b>Ações em massa</b>
                        </span>
                    </Wrapper>
                )}
                {!isMobile() && (
                    <Wrapper flexBox justifyContent='flex-end' alignItems='flex-end'>
                        <Radio.Group>
                            <Radio.Button
                                value={LotsTab.started}
                                style={{
                                    ...getSelectedTabStyle(LotsTab.started),
                                }}
                                onClick={() => handlePaginateTabChanged(LotsTab.started)}
                            >
                                {t('info.lots.filter.started')}
                            </Radio.Button>
                            <Radio.Button
                                value={LotsTab.negotiation}
                                style={{
                                    ...getSelectedTabStyle(LotsTab.negotiation),
                                }}
                                onClick={() => handlePaginateTabChanged(LotsTab.negotiation)}
                            >
                                {t('info.lots.filter.negotiation')}
                            </Radio.Button>
                            <Radio.Button
                                value={LotsTab.all}
                                style={{
                                    ...getSelectedTabStyle(LotsTab.all),
                                }}
                                onClick={() => handlePaginateTabChanged(LotsTab.all)}
                            >
                                {t('info.lots.filter.all')}
                            </Radio.Button>
                        </Radio.Group>
                    </Wrapper>
                )}
                {isMobile() && <FiltersModal onChange={handlePaginateTabChanged} />}
            </Wrapper>
        </Wrapper>
    );
};

export default LotListAreaHeader;
