import AdditionalInformation from '../AdditionalInformation';
import Bids from '../Bids';
import Proposals from '../Proposals';
import AuctionLotItems from '../AuctionLotItems';
import Scroll from '../Common/Scroll';
import { LotSelectedResumeProps } from './props';
import { StageLot } from '../../Interfaces/stageLot';
import ProviderValues from '../ProviderValues';
import { Spin, SpinArea } from 'Components/Common/Spin';
import { UseWindowEvent } from '../../Hooks/window-event.hook';
import { useState } from 'react';
import { isMobile, isSuspendedResume } from '../../helpers/mobile';
import Wrapper from '../Common/wrapper';
import Image from '../Common/Image';
import { useTranslation } from 'react-i18next';
import ProviderActions from '../ProviderActions';
import UnclassifiedProviderActions from 'Components/UnclassifiedProviderActions';
import { isAuctioneer, isProvider } from '../../helpers/permissions';
import MultipleWinnersList from '../MultipleWinnersList';
import Actions from 'Components/Actions';
import ApiService from 'services/api';

const stagesToShowProviderValues = [
    StageLot.awaiting_call_provider,
    StageLot.negotiation,
    StageLot.convoked,
    StageLot.negotiation_finished,
    StageLot.no_winner_finished,
    StageLot.awaiting_repeat_close_period,
    StageLot.repeat_second_close_period,
    StageLot.awaiting_rejudge,
];

const LotSelectedResume = (props: LotSelectedResumeProps) => {
    const { auctionNotice, lotSelected, authUser, socketConnection, serverTimestamp } = props;
    const [isOpened, setIsOpened] = useState(isSuspendedResume() ? false : true);
    const [winners, setWinners] = useState<{ proposalId: number; requestedAdditionalDocuments: boolean; providerAuctionCode: number; }[]>([]);
    const [loadingWinners, setLoadingWinners] = useState(true);

    const getWinners = async () => {
        setLoadingWinners(true);
        const response = await ApiService.getWinnersAdditionalDocuments(
            auctionNotice.id,
            lotSelected.id,
        );
        setWinners(response)
        setLoadingWinners(false);
    }

    const showProviderValues = stagesToShowProviderValues.includes(lotSelected.stage);

    UseWindowEvent(
        '@event.open-resume',
        () => {
            setIsOpened(true);
        },
        []
    );

    const getStyles = () => {
        if (isMobile() && isOpened) {
            return {
                width: '100%',
                top: '0',
                right: '0',
                left: '0',
                bottom: '0',
                position: 'absolute',
                height: '100%',
                zIndex: '1',
                background: '#fff',
            };
        }

        if (isSuspendedResume() && isOpened) {
            return {
                maxWidth: '320px',
                top: '0',
                right: '0',
                bottom: '0',
                position: 'absolute',
                height: '100%',
                zIndex: '1',
                borderLeft: '1px #eaeaea solid',
                background: '#fff',
            };
        }

        if (isOpened) {
            return {
                maxWidth: '320px',
                height: '100%',
                minWidth: isOpened ? '310px' : 'auto',
                margin: '0',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '5px 5px 0 10px',
            };
        }

        return {};
    };

    const { t } = useTranslation();

    const visibleMultipleWinners =
        !!lotSelected.allowMultipleWinner && lotSelected.stage === StageLot.negotiation_finished;

    const isClassifiedProvider = isProvider(authUser) && !props?.lotSelected?.isUnclassified
    const isUnclassifiedProvider = isProvider(authUser) && props?.lotSelected?.isUnclassified

    const getRender = () => {
        if (isOpened) {
            return (
                <>
                    <Wrapper style={{ position: 'relative', height: '100%' }}>
                        <AdditionalInformation
                            auctionNotice={auctionNotice}
                            auctionNoticeLotSelected={lotSelected}
                            authUser={authUser}
                            openedResume={isOpened}
                            onClose={() => setIsOpened(false)}
                        />
                        {isClassifiedProvider ? (
                            <ProviderActions
                                auctionNotice={auctionNotice}
                                auctionNoticeLotSelected={lotSelected}
                                authUser={authUser}
                            />
                        ) : null}
                        {isUnclassifiedProvider ? (
                            <UnclassifiedProviderActions
                                auctionNotice={auctionNotice}
                                auctionNoticeLotSelected={lotSelected}
                            />
                        ) : null}
                        {showProviderValues ? (
                            visibleMultipleWinners ? (
                                <MultipleWinnersList
                                    auction={auctionNotice}
                                    socketConnection={socketConnection}
                                    auctionLot={lotSelected}
                                    viewMode={!isAuctioneer(authUser)}
                                    getWinners={getWinners}
                                />
                            ) : (
                                <ProviderValues
                                    authUser={authUser}
                                    socketConnection={socketConnection}
                                    auctionNotice={auctionNotice}
                                    auctionNoticeLotSelected={lotSelected}
                                    serverTimestamp={serverTimestamp}
                                />
                            )
                        ) : (
                            <Bids
                                authUser={authUser}
                                auctionNotice={auctionNotice}
                                socketConnection={socketConnection}
                                auctionNoticeLotSelected={lotSelected}
                            />
                        )}
                        <Proposals
                            auctionNotice={auctionNotice}
                            socketConnection={socketConnection}
                            auctionNoticeLotSelected={lotSelected}
                            authUser={authUser}
                        />
                        {isAuctioneer(authUser) && (
                            <Actions
                                authUser={authUser}
                                auctionNotice={auctionNotice}
                                auctionNoticeLotSelected={lotSelected}
                                winners={winners}
                                getWinners={getWinners}
                                loadingWinners={loadingWinners}
                            />
                        )}
                        <AuctionLotItems
                            auctionNotice={auctionNotice}
                            authUser={authUser}
                            auctionNoticeLotSelected={lotSelected}
                        />
                    </Wrapper>
                </>
            );
        }

        if (!isOpened && !isMobile()) {
            return (
                <Wrapper
                    position='absolute'
                    width='45x'
                    height='45px'
                    flexBox
                    left='0'
                    top='125px'
                    bgcolor='#f5f5f5'
                    alignItems='center'
                    justifyContent='center'
                    padding='10px'
                    cursor='pointer'
                    title={t('info.open.chat')}
                    boxShadow='1px 2px 3px #9e9e9e'
                    margin='5px 0 0 0'
                    borderRadius='0 4px  4px 0'
                    onClick={() => setIsOpened(!isOpened)}
                >
                    <Image src='../../assets/icons/menu.png' width='30px' height='30px' />
                </Wrapper>
            );
        }
    };

    return (
        <Scroll style={getStyles()}>
            {socketConnection ? (
                getRender()
            ) : !isMobile() && !isSuspendedResume() ? (
                <SpinArea>
                    <Spin />
                </SpinArea>
            ) : null}
        </Scroll>
    );
};

export default LotSelectedResume;
