export enum SocketEvents {
    lotStarted = 'lotStarted',
    lotTiedStarted='lotTiedStarted',
    lotUpdated = 'lotUpdated',
    lotPaused = 'lotPaused',
    lotUnPaused = 'lotUnPaused',
    lotRenewed = 'lotRenewed',
    lotFinished = 'lotFinished',
    lotReopenNegotiation = 'lotReopenNegotiation',
    lotNegotiationFinished = 'lotNegotiationFinished',
    lotCanceled = 'lotCanceled',
    lotAwaitingRejudge = 'lotAwaitingRejudge',
    lotNoWinnerFinished = 'lotNoWinnerFinished',
    lotAwaitingCallProvider = 'lotAwaitingCallProvider',
    randomPeriodStarted = 'randomPeriodStarted',
    randomPeriodEnded = 'randomPeriodEnded',

    deletedBid = 'deletedBid',
    newBid = 'newBid',

    providerValuesCreated = 'providerValuesCreated',
    providerValuesUpdated = 'providerValuesUpdated',
    providerValueCalled = 'providerValueCalled',
    providerValueWinner = 'providerValueWinner',
    providerValueUpdated = 'providerValueUpdated',
    providerValueReseted = 'providerValueReseted',

    closeBidsCreated = 'closeBidsCreated',
    closeBidUpdated = 'closeBidUpdated',

    awaitingRepeatCloseBids = 'awaitingRepeatCloseBids',
    repeatCloseBidsCreated = 'repeatCloseBidsCreated',

    secondCloseBidsCreated = 'secondCloseBidsCreated',
    classifiedProposalLot = 'classifiedProposalLot',
    providerAuctionCodeCreated = 'providerAuctionCodeCreated',
    providerDisabled = 'providerDisabled',
    newMessage = 'newMessage',
    requestRefreshPage = 'requestRefreshPage',
    providerOnline = 'providerOnline',
    enableChatToProvider = 'enableChatToProvider',
    providerRequestSendMessage = 'providerRequestSendMessage',

    declineCreated = 'declineCreated',
    deferredCreated = 'deferredCreated',

    multipleWinnersUpdated = 'multipleWinnersUpdated',
    multipleWinnersToCover = 'multipleWinnersToCover',

    declineCoverLot = 'declineCoverLot',
    defineCollocations = 'defineCollocations',

    verificationOfProposalCompliance = 'verificationOfProposalCompliance',
    verificationOfProposalComplianceOrganization = 'verificationOfProposalComplianceOrganization',
}

export interface SocketEvent {
    type: SocketEvents;
    message: any;
}
