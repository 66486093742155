import { FC, useEffect, useState } from 'react';
import { AuctionLotItemProps } from './props';
import Wrapper from '../../../../Common/wrapper';
import CreateBid from '../../../../CreateBid';
import ApiService from '../../../../../services/api';
import Position from '../Position';
import Participants from '../Participants';
import BestBid from '../BestBid';
import BidPercentDiff from '../BidPercentDiff';
import { pad } from '../../../../../helpers/pad';
import { StageLot } from '../../../../../Interfaces/stageLot';
import ConditionalTimer from '../../../../ConditionalTimer';
import { methodDisputeDoAction } from '../../../../../helpers/methodDispute';
import { diffBetweenNowAndExtensionTime } from '../../../../../helpers/lotPeriodShared';
import { CONSTANTS } from '../../../../../constants';
import { Lot, LotItem } from './styled';
import { useTranslation } from 'react-i18next';
import ConfirmationPopover from '../../../../Common/ConfirmationPopover';
import { Button } from 'antd';
import { addNotification } from '../../../../../helpers/notification';
import { isDeclinedLot } from '../../../../../helpers/declined';
import { stagesToGetBestCloseBids } from 'Components/AuctionLot';
import { ClosedBidStatus } from 'Interfaces/closeBids';
import { ProviderValueStatus } from 'Interfaces/providerValues';
import { LotTiebreakerRound } from 'Interfaces/auctionNoticeLot';
import { enableDefineCollocations } from 'helpers/enable-define-collocations.helper';


const AuctionLotItemSupplier: FC<AuctionLotItemProps> = ({
    auctionLot,
    onSelectAuctionLot,
    selected,
    auctionNotice,
    onProviderBestBidLoaded,
    onBestBidLoaded,
    changeStage,
    onFinishProviderPeriodConvoked,
    authUser,
    serverTimestamp,
    closeBids,
    providerValues,
}) => {
    const closedPeriodTimer = CONSTANTS.timersDuration.CLOSE_PERIOD;
    const { t } = useTranslation();
    const [inBidPeriod, setInBidPeriod] = useState(false)
    const inTimeToDefineCollocations = enableDefineCollocations(auctionNotice.auctionTypeRules, auctionLot.hasWinner) && auctionLot.stage === StageLot.started;

    const getBestBid = async () =>
        await ApiService.getBestBid(auctionLot.auctionNoticeId, auctionLot.id);

    const getProviderBestBid = async () =>
        await ApiService.getProviderBestBid(auctionLot.auctionNoticeId, auctionLot.id);

    const getProviderBestCloseBid = async () => {
        if (stagesToGetBestCloseBids.includes(auctionLot.stage)) {
            return await ApiService.getProviderBestCloseBid(
                auctionLot.auctionNoticeId,
                auctionLot.id
            );
        }

        return getProviderBestBid();
    };

    const handleProviderBestBid = async () => {
        const action = methodDisputeDoAction({
            methodDispute: auctionNotice.methodDispute,
            open: () => getProviderBestBid(),
            closed: () => getProviderBestCloseBid(),
        });

        if (!action) return;
        const bestProviderBid = await action();

        if (!bestProviderBid)
            return onProviderBestBidLoaded({
                lotId: auctionLot.id,
                value: null,
            });

        return onProviderBestBidLoaded(bestProviderBid);
    };

    const getBestCloseBid = async () => {
        if (stagesToGetBestCloseBids.includes(auctionLot.stage)) return;
        return await getBestBid();
    };

    const handleBestBid = async () => {
        const action = methodDisputeDoAction({
            methodDispute: auctionNotice.methodDispute,
            open: () => getBestBid(),
            closed: () => getBestCloseBid(),
        });

        if (!action) return;
        const bestBid = await action();

        if (!bestBid)
            return onBestBidLoaded({
                lotId: auctionLot.id,
                value: null,
            });

        return onBestBidLoaded(bestBid);
    };

    useEffect(() => {
        handleBestBid();
        handleProviderBestBid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPossibleSendBidInOpenPeriod = () => {
        if (auctionLot.stage !== StageLot.started) return false;
        if (!auctionLot.tiebreakerRound) return true;
        const finalProposal = providerValues?.find(({ providerAuctionCode }) => providerAuctionCode === authUser.providerAuctionCode);
        if (auctionLot.tiebreakerRound === LotTiebreakerRound.ME_EPP && finalProposal?.status === ProviderValueStatus.tied_me_epp) return true;
        if (auctionLot.tiebreakerRound === LotTiebreakerRound.WINNER && finalProposal?.status === ProviderValueStatus.tied) return true;
        return false;
    }

    const bidPeriodOpenMode = () => {
        if (isDeclinedLot(auctionLot)) {
            return false;
        }
        if (inTimeToDefineCollocations) {
            return auctionLot.winnerProvider?.providerAuctionCode !== authUser.providerAuctionCode;
        }

        return (
            isPossibleSendBidInOpenPeriod() ||
            auctionLot.convokedProvider?.providerAuctionCode === authUser.providerAuctionCode ||
            (auctionLot.stage === StageLot.negotiation &&
                auctionLot.winnerProvider?.providerAuctionCode === authUser.providerAuctionCode) ||
            auctionLot.stage === StageLot.random_period
        );
    };

    const statusCloseBidToShow = [ClosedBidStatus.called, ClosedBidStatus.covered];
    const providerInCloseBids = closeBids.find(
        (closeBid) =>
            closeBid.providerAuctionCode === authUser.providerAuctionCode &&
            statusCloseBidToShow.includes(closeBid.status)
    );

    const bidPeriodCloseMode = () => {
        if (isDeclinedLot(auctionLot)) {
            return false;
        }
        if (inTimeToDefineCollocations) {
            return auctionLot.winnerProvider?.providerAuctionCode !== authUser.providerAuctionCode;
        }
        const inClosePeriod = () =>
            diffBetweenNowAndExtensionTime({
                auctionLot,
                closedPeriodTimer,
                serverDifference: serverTimestamp.difference,
            });
        const secondsToFinish = inClosePeriod();

        if (auctionLot.convokedProvider?.providerAuctionCode === authUser.providerAuctionCode) {
            return true;
        }
        return (
            (auctionLot.stage === StageLot.negotiation &&
                auctionLot.winnerProvider?.providerAuctionCode === authUser.providerAuctionCode) ||
            auctionLot.stage === StageLot.started ||
            auctionLot.stage === StageLot.random_close_period ||
            auctionLot.stage === StageLot.random_period ||
            ((auctionLot.stage === StageLot.close_period ||
                auctionLot.stage === StageLot.second_close_period ||
                auctionLot.stage === StageLot.repeat_second_close_period) &&
                secondsToFinish > 0 &&
                !!providerInCloseBids)
        );
    };

    const checkIfLotInBidPeriod = () => {
        const action = methodDisputeDoAction({
            methodDispute: auctionNotice.methodDispute,
            open: () => bidPeriodOpenMode(),
            closed: () => bidPeriodCloseMode(),
        });
        
        if (!action) return false;

        const result = action();
        return !!result;
    };

    useEffect(() => {
        setInBidPeriod(checkIfLotInBidPeriod());
    }, [auctionLot.stage, auctionLot.convokedProvider, auctionLot.winnerProvider, providerInCloseBids, providerValues])

    const handleNotCoverClick = async () => {
        let error: any;
        await ApiService.declineCover(auctionLot.auctionNoticeId, auctionLot.id, (err) => {
            error = err;
        });

        if (error) {
            return addNotification(
                t('term.error'),
                t('info.default.api.error.message'),
                'danger',
                4000
            );
        }

        return addNotification(t('term.success'), t('term.success'), 'success', 3000);
    };

    const replacedLotName = `${t('term.lot.title')} ${pad(auctionLot.item)}`;
    const isCota = `${auctionLot.quotaId !== null ? ` (${t('term.quota')})` : ''}`;

    const convoked =
        auctionLot.convokedProvider?.providerAuctionCode === authUser.providerAuctionCode;

    return (
        <Lot
            key={`supplierLot:${auctionLot.id}`}
            borderRight={selected ? '1px solid #fff' : '1px solid #D6D6D6'}
            padding='5px 10px'
            flexBox
            cursor='pointer'
            justifyContent='center'
            bgcolor={selected ? '#FFF' : '#f5f5f5'}
            onClick={() => onSelectAuctionLot(auctionLot)}
            position='relative'
            height='86px'
        >
            <Wrapper flexBox alignItems='center'>
                <Position
                    authUser={authUser}
                    auctionLot={auctionLot}
                    auctionNotice={auctionNotice}
                />
                <Wrapper opacity={inBidPeriod ? '1' : '0.5'}>
                    <LotItem title={`${replacedLotName}${isCota}`}>
                        <span>{replacedLotName}</span>
                        <span>{isCota}</span>
                    </LotItem>
                    <Participants auctionLot={auctionLot} />
                </Wrapper>
            </Wrapper>
            <Wrapper flexBox alignItems='center' opacity={inBidPeriod ? '1' : '0.5'}>
                <Wrapper
                    flexBox
                    justifyContent='center'
                    width='100px'
                    margin='0 13px'
                    alignItems='center'
                >
                    <ConditionalTimer
                        key={`timer:${auctionLot.id}`}
                        auctionLot={auctionLot}
                        auctionNotice={auctionNotice}
                        changeStage={changeStage}
                        onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                        onFinishTimerLot={() => {}}
                        authUser={authUser}
                        serverTimestamp={serverTimestamp}
                        bidPeriodCloseMode={bidPeriodCloseMode()}
                    />
                </Wrapper>
                <BidPercentDiff auctionNotice={auctionNotice} auctionLot={auctionLot} />
                <BestBid
                    auctionNotice={auctionNotice}
                    decimalPlaces={auctionNotice.decimalPlaces}
                    auctionLot={auctionLot}
                />
                <Wrapper
                    margin='0 0 0 8px'
                    height='100%'
                    display='flex'
                    flexDirection='column'
                    justifyContent={convoked ? 'space-evenly' : 'center'}
                >
                    <CreateBid
                        selected={selected}
                        auctionNotice={auctionNotice}
                        activeBids={inBidPeriod}
                        auctionLot={auctionLot}
                    />
                    {convoked && (
                        <>
                            <ConfirmationPopover
                                key={`confirmationBid:${auctionLot.id}`}
                                render={
                                    <>
                                        <Wrapper fontSize='13px'>
                                            <p>{t('info.not.cover.message')}</p>
                                        </Wrapper>
                                    </>
                                }
                                maxWidth='220px'
                                onConfirm={handleNotCoverClick}
                            >
                                <Wrapper>
                                    <Button size='small'>{t('info.not.cover')}</Button>
                                </Wrapper>
                            </ConfirmationPopover>
                        </>
                    )}
                </Wrapper>
            </Wrapper>
        </Lot>
    );
};

export default AuctionLotItemSupplier;
