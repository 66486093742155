import { AppealStatusType, AuctionNotice } from '../Interfaces/auctionNotice';
import moment from 'moment';
import { AuctionNoticeLot } from '../Interfaces/auctionNoticeLot';

export const canIntentionToAppeal = (auction: AuctionNotice, auctionLot: AuctionNoticeLot) => {
    const isEnabled = auction.auctionTypeRules?.generalSettings?.solicitacoes?.recurso?.habilitar
    const hasSecondIntent = auction.auctionTypeRules?.generalSettings?.solicitacoes?.recurso?.habilitarDuplaIntencaoDeRecursos;
    
    if (!isEnabled) {
        return false;
    }

    const withinTheDeadline =
        moment.utc(auction.deadlineIntentAppeal).valueOf() > moment().valueOf();

    const lotInTheDeadline =
        moment.utc(auctionLot.secondDeadlineIntentAppeal).valueOf() > moment().valueOf();
    
    if (!!auctionLot.secondDeadlineIntentAppeal && hasSecondIntent && lotInTheDeadline) {
        return true
    }

    if (!withinTheDeadline) {
        return false;
    }

    if (auctionLot.lotStage !== 'intentionAppeal' && auction.isPhaseReversal !== 1) {
        return false;
    }

    if (auction.appealStatus === AppealStatusType.closed  && auction.isPhaseReversal === 1) {
        return false;
    }

    return true;
};
