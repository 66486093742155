import React, { FC, ReactElement, useState } from 'react';
import EnableIntentionToAppealView from './EnableIntentionToAppealView';

export enum IntentionToAppealOption {
    'SELECTED_LOT' = 1,
    'ALL' = 2,
}

interface EnableIntentionToAppealProps {
    actionButton: ReactElement<any>;
    actionEvent?: 'onConfirm' | 'onClick';
    onConfirm?: (enableToAllLots: boolean) => Promise<void>
    lotItem?: number;
}

const EnableIntentionToAppeal: FC<EnableIntentionToAppealProps> = ({ onConfirm, lotItem, actionButton, actionEvent='onClick' }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [intentionToAppealOption, setIntentionToAppealOption] = useState<IntentionToAppealOption>(IntentionToAppealOption.SELECTED_LOT);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setIntentionToAppealOption(IntentionToAppealOption.SELECTED_LOT);
    };

    const handeOnConfirm = async () => {
        try {
            setIsLoading(true);
            await onConfirm?.(intentionToAppealOption === IntentionToAppealOption.ALL);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
            closeModal();
        }
    };

    const cloneActionButton = React.cloneElement(actionButton as ReactElement<any>, {
        [actionEvent]: () => {
            showModal();
        },
    });


    return (
        <EnableIntentionToAppealView
            lotItem={lotItem}
            actionButton={cloneActionButton}
            visible={isModalVisible}
            loading={isLoading}
            disabled={isLoading}
            option={intentionToAppealOption}
            onSelectOption={(e) => setIntentionToAppealOption(Number(e.target.value))}
            onCancel={closeModal}
            onConfirm={handeOnConfirm}
        />
    );
};

export default EnableIntentionToAppeal;
