import { FC } from 'react';
import { AuctionNotice } from 'Interfaces/auctionNotice';
import { isProvider, isAuctioneer } from 'helpers/permissions';
import { User } from 'Interfaces/user';
import ModalVerificationOfProposalComplianceProvider from './modal-verification-of-proposal-compliance-provider';
import ModalVerificationOfProposalComplianceOrganization from './modal-verification-of-proposal-compliance-organization';

interface ModalVerificationOfProposalComplianceProps {
    process: AuctionNotice;
    authUser: User;
    socketConnection: any;
}

const ModalVerificationOfProposalCompliance: FC<ModalVerificationOfProposalComplianceProps> = ({
    socketConnection,
    process,
    authUser,
}) => {
    if (isProvider(authUser)) {
        return (
            <ModalVerificationOfProposalComplianceProvider
                process={process}
                socketConnection={socketConnection}
            />
        );
    }
    if (isAuctioneer(authUser)) {
        return (
            <ModalVerificationOfProposalComplianceOrganization
                process={process}
                socketConnection={socketConnection}
            />
        );
    }
    return <></>;
};

export default ModalVerificationOfProposalCompliance;
