import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Radio, RadioChangeEvent, Space } from 'antd';
import Wrapper from 'Components/Common/wrapper';
import { IntentionToAppealOption } from './EnableIntetionToAppeal';

interface EnableIntentionToAppealProps {
    option?: IntentionToAppealOption;
    onSelectOption?: (e: RadioChangeEvent) => void;
    actionButton: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    visible: boolean;
    loading?: boolean;
    disabled?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    lotItem?: number;
}

const EnableIntentionToAppealView: FC<EnableIntentionToAppealProps> = ({
    actionButton,
    visible,
    onCancel,
    loading,
    disabled,
    lotItem,
    option,
    onConfirm,
    onSelectOption,
}) => {
    const { t } = useTranslation();

    const intentionToAppealOptions: Record<IntentionToAppealOption, string> = {
        [IntentionToAppealOption.SELECTED_LOT]: t('info.open-appeal-intent-to-lot', { lotItem }),
        [IntentionToAppealOption.ALL]: t('info.open-appeal-intent-to-all'),
    };

    return (
        <>
            {visible && (
                <Modal
                    title={t('info.open-appeal-intent')}
                    width='400px'
                    visible={visible}
                    onCancel={onCancel}
                    footer={
                        <Wrapper flexBox justifyContent='flex-end'>
                            <Button onClick={onCancel} disabled={loading}>
                                {t('info.cancel')}
                            </Button>
                            <Button
                                disabled={disabled || loading}
                                type='primary'
                                loading={loading}
                                onClick={onConfirm}
                            >
                                {t('term.confirm')}
                            </Button>
                        </Wrapper>
                    }
                >
                    <Wrapper>
                        <Form.Item
                            label={<strong>{t('term.select')}:</strong>}
                        >
                            <Radio.Group onChange={onSelectOption} value={option}>
                                <Space direction='vertical'>
                                    {Object.keys(intentionToAppealOptions).map((key) => {
                                        const text = intentionToAppealOptions[key];
                                        return <Radio value={Number(key)}>{text}</Radio>;
                                    })}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Wrapper>
                </Modal>
            )}
            {actionButton}
        </>
    );
};

export default EnableIntentionToAppealView;
