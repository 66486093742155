import { FC, useState } from 'react';
import { currencyFormat, formatValueWithAuctionRules } from '../../helpers/currencyTransform';
import { Alert, Input, Modal, Radio, RadioChangeEvent } from 'antd';
import Wrapper from '../Common/wrapper';
import { BidInput } from '../CreateBid/styled';
import { AuctionNotice } from '../../Interfaces/auctionNotice';
import { MultipleWinnersToCover } from '../../Interfaces/multipleWinners';
import { useTranslation } from 'react-i18next';
import { addNotification } from '../../helpers/notification';
import { pad } from '../../helpers/pad';
import ApiService from '../../services/api';

interface ModalFormViewProps {
    auction: AuctionNotice;
    providerValueToCover: MultipleWinnersToCover;
    onClose: () => void;
}

const ModalFormView: FC<ModalFormViewProps> = ({ auction, providerValueToCover, onClose }) => {
    const [value, setValue] = useState<number | undefined>(undefined);
    const [bidValue, setBid] = useState<number | undefined>(undefined);
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);

    const { judgmentCriterion, rateTypeBid, decimalPlaces } = auction;
    const { t } = useTranslation();

    const { formatted } = formatValueWithAuctionRules(
        providerValueToCover?.valueToCover,
        judgmentCriterion,
        rateTypeBid,
        decimalPlaces
    );

    const multipleWinnerCover = async () => {
        if (!providerValueToCover) {
            return;
        }

        setSending(true);

        let error: any;
        await ApiService.multipleWinnerCover(
            auction.id,
            providerValueToCover.multipleWinner.lotId,
            0,
            bidValue,
            value === 2 ? 'custom' : 'full',
            message,
            (err) => {
                error = err;
            }
        );

        setSending(false);

        if (error) {
            return;
        }

        onClose();
        return addNotification(t('term.success'), t('term.success'), 'success', 3000);
    };

    const multipleWinnerReject = async () => {
        let error: any;
        await ApiService.multipleWinnerReject(
            auction.id,
            providerValueToCover.multipleWinner.lotId,
            0,
            (err) => {
                error = err;
            }
        );

        if (error) {
            return;
        }

        onClose();
        return addNotification(t('term.success'), t('term.success'), 'success', 3000);
    };

    const doAction = async () => {
        if (value === 3) {
            return await multipleWinnerReject();
        }

        return await multipleWinnerCover();
    };

    const visibleConditionally = auction?.auctionTypeRules?.generalSettings?.disputa?.permitirContraPropostaMultiplosVencedores;

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const getIsValid = () => (value === 2 && bidValue && message) || value === 1 || value === 3;

    const messageConditionally = visibleConditionally ? ' Você também pode aceitar condicionalmente e dar um último lance para avaliação do pregoeiro, se ele não aceitar será dada a oportunidade a outro.' : '';
    return (
        <>
            {providerValueToCover && (
                <Modal
                    title={t('info.helper.convoked2.title')}
                    visible={true}
                    okText={t('term.confirm')}
                    width='550px'
                    onOk={doAction}
                    okButtonProps={{
                        disabled: !getIsValid(),
                        loading: sending,
                    }}
                    onCancel={() => {
                        onClose();
                    }}
                >
                    <Wrapper>
                        <Wrapper margin='0 0 10px 0'>
                            Você está sendo convocado para assumir a{' '}
                            {providerValueToCover.multipleWinner.position} posição do lote{' '}
                            <b>{pad(providerValueToCover.lotItem)}</b> cujo melhor lance é de{' '}
                            <b>{formatted}</b> do 1º colocado.
                        </Wrapper>
                        <Alert
                            type='warning'
                            message={`Ao aceitar será dado um lance no mesmo valor do primeiro colocado. Se
                            você recusar, a oportunidade será dada a outro fornecedor.${messageConditionally}`}
                        ></Alert>
                        <Wrapper margin='10px 0 0 0'>
                            <Radio.Group onChange={onChange} value={value}>
                                <Radio value={1}>Aceitar</Radio>
                                {visibleConditionally && (
                                    <Radio value={2}>Aceitar condicionalmente</Radio>
                                )}
                                <Radio value={3}>Recusar</Radio>
                            </Radio.Group>
                        </Wrapper>
                        {value === 2 ? (
                            <Wrapper display='flex' margin='15px 0 0 0'>
                                <Wrapper margin='0 10px 0 0'>
                                    {(auction.judgmentCriterion === 1 &&
                                        auction.rateTypeBid === 1) ||
                                    auction.judgmentCriterion === 2 ? (
                                        <BidInput
                                            autoFocus
                                            allowNegative={auction.rateTypeBid === 1}
                                            value={bidValue ?? 0}
                                            prefix='% '
                                            className='inputBid'
                                            decimalSeparator=','
                                            precision={auction.decimalPlaces}
                                            thousandSeparator='.'
                                            onChangeEvent={(__: any, _: any, floatValue: any) =>
                                                setBid(floatValue)
                                            }
                                        />
                                    ) : (
                                        <BidInput
                                            autoFocus
                                            allowNegative={auction.rateTypeBid === 1}
                                            value={bidValue ?? 0}
                                            prefix={`${currencyFormat} `}
                                            decimalSeparator=','
                                            thousandSeparator='.'
                                            className='inputBid'
                                            precision={auction.decimalPlaces}
                                            onChangeEvent={(__: any, _: any, floatValue: any) =>
                                                setBid(floatValue)
                                            }
                                        />
                                    )}
                                </Wrapper>
                                <Input
                                    value={message}
                                    onChange={(ev) => setMessage(ev.target.value)}
                                    maxLength={300}
                                    placeholder={'Justificativa'}
                                />
                            </Wrapper>
                        ) : null}
                    </Wrapper>
                </Modal>
            )}
        </>
    );
};

export default ModalFormView;
