import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { Item, Title } from '../../styled';
import ApiService from 'services/api';

export interface ToggleShowReferenceValueToWinnerProps {
  processId: number;
  lotId: number;
  showReferenceValueToWinnerProp: boolean;
}


const ToggleShowReferenceValueToWinner: FC<ToggleShowReferenceValueToWinnerProps> = ({ processId, lotId, showReferenceValueToWinnerProp }) => {
  const { t } = useTranslation();
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const [showReferenceValueToWinner, setShowReferenceValueToWinner] = useState(
    showReferenceValueToWinnerProp ?? false
  );
  
  useEffect(() => {
    setShowReferenceValueToWinner(showReferenceValueToWinnerProp)
  }, [showReferenceValueToWinnerProp])

  const handleToggleSwitch = async () => {
    try {
        setLoadingSwitch(true);
        await ApiService.toggleShowReferenceValueToWinner(processId, lotId, !showReferenceValueToWinner, () => {});
        setShowReferenceValueToWinner((state) => state);
    } catch(e) {
      console.log(e);
    } finally {
      setLoadingSwitch(false);
    }
  };

  return (
    <Item style={{ margin: '5px 0 0 2px'}}>
      <Title>
        <span>{t('info.lot.show-reference-value-to-winner')}</span>
      </Title>
      <Switch
        loading={loadingSwitch}
        disabled={loadingSwitch}
        checked={showReferenceValueToWinner}
        onChange={() => handleToggleSwitch()}
      />
    </Item>
  );
};

export default ToggleShowReferenceValueToWinner;
