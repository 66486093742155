import { StageLot } from '../../../../../Interfaces/stageLot';
import RoundedButton from '../RoundedButton';
import { ModeOpenActionsProps } from './props';
import { AuctioneerActions } from '../interface/AuctioneerActions';
import { ConfirmationModalType } from '../../../../../Interfaces/confirmationModal';
import { addNotification } from '../../../../../helpers/notification';
import Wrapper from '../../../../Common/wrapper';
import { ProviderValueStatus } from 'Interfaces/providerValues';
import { AuctionNoticeType } from 'Interfaces/auctionNotice';
import { useTranslation } from 'react-i18next';
import { featureFlagEnabled } from 'helpers/can-access';
import ModalDeclassifyWinner from '../../ModalDeclassifyWinner';
import { enableDefineCollocations } from 'helpers/enable-define-collocations.helper';
import { enableVerificationOfProposalCompliance } from 'helpers/enable-verification-of-proposal-compliance.helper';

const ModeOpenActions = ({
    doAction,
    auctionLot,
    providerValues,
    auctionNotice,
    authUser,
}: ModeOpenActionsProps) => {
    const { t } = useTranslation();

    const handleRenewLot = () => {
        if (!!auctionLot.bidRenewDate) {
            return addNotification(t('term.error'), t('info.error.renew-lot'), 'warning', 3000);
        }

        return doAction(AuctioneerActions.renew);
    };

    const randomRender = (message: string, confirmation: string) => (
        <Wrapper fontSize='13px'>
            <p>{message}</p>
            <p>{confirmation}</p>
        </Wrapper>
    );

    const cancelButton = (
        <RoundedButton
            title={t('info.action.cancel.title')}
            color='var(--platform-danger-color)'
            render={randomRender(
                t('info.action.cancel.message'),
                t('info.action.cancel.confirmation')
            )}
            confirmationType={ConfirmationModalType.frustate}
            margin='0 13px 0 13px'
            icon='stop1'
            onConfirm={(values) => doAction(AuctioneerActions.cancel, values)}
        />
    );

    const winner = providerValues?.find(
        (providerValue) => providerValue.status === ProviderValueStatus.winner
    );

    const blockButton = !!auctionLot?.winnerProvider?.id ? (
        <ModalDeclassifyWinner
            onConfirm={(justification) =>
                doAction(AuctioneerActions.desclassify, {
                    providerValueId: auctionLot?.winnerProviderId ?? auctionLot?.winnerProvider?.id,
                    justification,
                })
            }
            actionButton={
                <RoundedButton
                    title={t('info.declassify.winner')}
                    color='var(--platform-danger-color)'
                    render={
                        <Wrapper fontSize='13px'>
                            <p>{t('info.declassify.winner.confirmation')}</p>
                        </Wrapper>
                    }
                    confirmationType={ConfirmationModalType.button}
                    margin='0 13px 0 13px'
                    icon='block'
                />
            }
            lotItem={auctionLot?.item}
            providerNumber={winner?.providerAuctionCode}
        />
    ) : undefined;

    const verificationOfProposalComplianceButton = (
        <RoundedButton
            title={t('info.action.avanced.stage.title')}
            render={randomRender(
                t('info.action.avanced.stage.message'),
                t('info.action.avanced.stage.confirmation')
            )}
            color='var(--platform-secondary-color)'
            confirmationType={ConfirmationModalType.button}
            icon='finish'
            onConfirm={() => doAction(AuctioneerActions.verificationOfProposalCompliance)}
        />
    );

    const changeLotStatusToNegotiationButton = (
        <RoundedButton
            title={t('info.action.avanced.stage.title')}
            render={randomRender(
                t('info.action.avanced.stage.message'),
                t('info.action.avanced.stage.confirmation')
            )}
            color='var(--platform-secondary-color)'
            confirmationType={ConfirmationModalType.button}
            icon='finish'
            onConfirm={() => doAction(AuctioneerActions.changeLotStatusToNegotiation)}
        />
    );

    const actions = () => {
        const callableProviders = providerValues.filter(
            (providerValue) => providerValue.status === ProviderValueStatus.callable
        );

        switch (auctionLot.stage) {
            case StageLot.awaiting_define_collocations:
                return (
                    <>
                        <RoundedButton
                            title='Definir demais colocações'
                            color='var(--platform-warning-color)'
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.collocation-avanced.stage.message')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            confirmationType={ConfirmationModalType.button}
                            margin='0 13px 0 13px'
                            icon='startAgain'
                            onConfirm={() => doAction(AuctioneerActions.doDefineCollocations)}
                        />
                        {enableVerificationOfProposalCompliance(auctionNotice.auctionTypeRules)
                            ? verificationOfProposalComplianceButton
                            : changeLotStatusToNegotiationButton}
                    </>
                );
            case StageLot.awaiting_define_collocations_ended:
                return (
                    <>
                        {enableVerificationOfProposalCompliance(auctionNotice.auctionTypeRules)
                            ? verificationOfProposalComplianceButton
                            : changeLotStatusToNegotiationButton}
                    </>
                );
            case StageLot.unStarted:
                return (
                    <RoundedButton
                        color='#777777'
                        margin='0 13px 0 13px'
                        title={t('info.stage.unstarted.title')}
                        bgColor='#777777'
                        icon='play'
                        render={randomRender(
                            t('info.stage.unstarted.message'),
                            t('info.stage.unstarted.confirmation')
                        )}
                        confirmationType={ConfirmationModalType.button}
                        onConfirm={() => doAction(AuctioneerActions.start)}
                    />
                );

            case StageLot.started:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.action.started.title')}
                            confirmationType={ConfirmationModalType.message}
                            render={randomRender(
                                t('info.action.started.message'),
                                t('info.action.started.confirmation')
                            )}
                            onConfirm={(message: string) =>
                                doAction(AuctioneerActions.pause, { message })
                            }
                            color='var(--platform-secondary-color)'
                            icon='pause1'
                        />
                    </>
                );

            case StageLot.timeEnded:
                return (
                    <>
                        {auctionNotice.auctionType === AuctionNoticeType.E &&
                            !auctionLot.hasWinner && (
                                <RoundedButton
                                    title={
                                        !!auctionLot.bidRenewDate
                                            ? t('info.action.time-ended.restarted.title')
                                            : t('info.action.time-ended.restart.title')
                                    }
                                    color='var(--platform-warning-color)'
                                    render={randomRender(
                                        t('info.action.time-ended.restart.message'),
                                        t('info.action.avanced.stage.confirmation')
                                    )}
                                    confirmationType={ConfirmationModalType.button}
                                    margin='0 13px 0 13px'
                                    icon='startAgain'
                                    onConfirm={() => handleRenewLot()}
                                />
                            )}
                        {auctionNotice.isRandomTime && !auctionLot.tiebreakerRound ? (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={randomRender(
                                    t('info.action.time-ended.restarted.message'),
                                    t('info.action.avanced.stage.confirmation')
                                )}
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.startRandomPeriod)}
                            />
                        ) : (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={randomRender(
                                    t('info.action.avanced.stage.message'),
                                    t('info.action.avanced.stage.confirmation')
                                )}
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.finish)}
                            />
                        )}
                    </>
                );

            case StageLot.random_period_ended:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.action.avanced.stage.title')}
                            render={randomRender(
                                t('info.action.avanced.stage.message'),
                                t('info.action.avanced.stage.confirmation')
                            )}
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.finish)}
                        />
                    </>
                );

            case StageLot.random_period:
                return <>{cancelButton}</>;
            case StageLot.verification_of_proposal_compliance:
                return <>{blockButton}</>;
            case StageLot.negotiation:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.stage.negotiation.title')}
                            render={randomRender(
                                t('info.stage.negotiation.message'),
                                t('info.action.avanced.stage.confirmation')
                            )}
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.finishNegotiation)}
                        />
                        {blockButton}
                    </>
                );

            case StageLot.convoked:
                return <>{cancelButton}</>;

            case StageLot.finished:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.action.avanced.stage.title')}
                            render={randomRender(
                                t('info.action.avanced.stage.message'),
                                t('info.action.avanced.stage.confirmation')
                            )}
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.finish)}
                        />
                    </>
                );

            case StageLot.awaiting_rejudge:
                return (
                    <>
                        {cancelButton}
                        {callableProviders.length > 0 ? (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.stage.awaiting-rejudge.message')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                omitConfirmButton
                                onConfirm={() => {}}
                            />
                        ) : (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={randomRender(
                                    t('info.action.avanced.stage.message'),
                                    t('info.action.avanced.stage.confirmation')
                                )}
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.finish)}
                            />
                        )}
                    </>
                );

            case StageLot.awaiting_call_provider:
                return (
                    <>
                        {featureFlagEnabled(
                            'solicitacaoDocumentoComplementarDisputa',
                            authUser
                        ) && (
                            <RoundedButton
                                title={t('info.position.lot.reconvoke.title')}
                                color='var(--platform-warning-color)'
                                render={randomRender(
                                    t('info.position.lot.reconvoke'),
                                    t('info.action.avanced.stage.confirmation')
                                )}
                                confirmationType={ConfirmationModalType.button}
                                margin='0 0 0 13px'
                                icon='startAgain'
                                onConfirm={() => doAction(AuctioneerActions.renewCover)}
                            />
                        )}
                        {cancelButton}
                        {callableProviders.length > 0 ? (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.stage.awaiting.call.provider.message')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                omitConfirmButton
                                onConfirm={() => {}}
                            />
                        ) : (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={randomRender(
                                    t('info.action.avanced.stage.message'),
                                    t('info.action.avanced.stage.confirmation')
                                )}
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.finish)}
                            />
                        )}
                    </>
                );

            case StageLot.paused:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.stage.paused.title')}
                            render={randomRender(
                                t('info.stage.paused.message'),
                                t('info.action.avanced.stage.confirmation')
                            )}
                            color='#777777'
                            confirmationType={ConfirmationModalType.button}
                            bgColor='#777777'
                            icon='play'
                            onConfirm={() => doAction(AuctioneerActions.unPause)}
                        />
                    </>
                );

            case StageLot.negotiation_finished:
                return (
                    <RoundedButton
                        color='var(--platform-warning-color)'
                        margin='0 13px 0 13px'
                        title={t('info.stage.negotiation-finished.title')}
                        icon='startAgain'
                        render={
                            <Wrapper fontSize='13px'>
                                <p>
                                    <p>{t('info.stage.negotiation-finished.message')}</p>
                                </p>
                                <p>{t('info.action.avanced.stage.confirmation')}</p>
                            </Wrapper>
                        }
                        confirmationType={ConfirmationModalType.button}
                        onConfirm={() => doAction(AuctioneerActions.reOpenNegotiation)}
                    />
                );

            case StageLot.canceled:
                return (
                    <>
                        <RoundedButton
                            title={t('info.confirm-cancel-lot')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{`${t('info.confirm-cancel-lot')}?`}</p>
                                </Wrapper>
                            }
                            color='var(--platform-warning-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='startAgain'
                            onConfirm={() => doAction(AuctioneerActions.revertCancel)}
                        />
                    </>
                );

            default:
                return <></>;
        }
    };

    return actions();
};

export default ModeOpenActions;
