import { FC, useState } from 'react';
import Wrapper from '../Common/wrapper';
import { Modal } from 'antd';
import styled from 'styled-components';
import { HiStatusOnline } from 'react-icons/hi';
import { Badge as DefaultBadge } from 'antd';
import { ProposalToConfirm } from 'services/api/interfaces';

const Icon = styled(HiStatusOnline)`
    width: 26px;
    height: 26px;
    color: var(--platform-secondary-color);
`;

const Wrapped = styled(Wrapper)`
    height: 44px;
    border: 1px solid rgba(204, 204, 204, 0.5);
`;

interface BaseModalProps {
    getConfirmations: (setIsModalVisible: (value: React.SetStateAction<boolean>) => void) => JSX.Element | JSX.Element[]
    proposalsToConfirm: ProposalToConfirm[]
}

const BaseModal: FC<BaseModalProps> = ({
    getConfirmations,
    proposalsToConfirm,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Modal
                title='Propostas atualizadas a confirmar'
                visible={isModalVisible}
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
            >
                {getConfirmations(setIsModalVisible)}
            </Modal>
            <Wrapped
                minWidth='295px'
                maxWidth='200px'
                bgcolor={proposalsToConfirm.length ? '#ffffb58f' : '#fff'}
                textAlign='center'
                padding='12px 24px'
                justifyContent='space-between'
                alignItems='center'
                borderRadius='5px'
                flexBox
            >
                <Wrapper flexBox alignItems='center' onClick={() => setIsModalVisible(true)} cursor='pointer'>
                    <Icon />
                    <span style={{ margin: '0 0 0 8px', fontSize: 15 }}>
                        <b>Conformidade da proposta</b>
                    </span>
                </Wrapper>
                <DefaultBadge showZero count={proposalsToConfirm.length} />
            </Wrapped>
        </>
    );
};

export default BaseModal;
