export enum AuctioneerActions {
    start = 'start',
    finish = 'finish',
    renew = 'renew',
    renewCover = 'renewCover',
    pause = 'pause',
    cancel = 'cancel',
    desclassify = 'desclassify',
    finishNegotiation = 'finishNegotiation',
    startClosePeriod = 'startClosePeriod',
    startSecondClosePeriod = 'startSecondClosePeriod',
    unPause = 'unPause',
    reOpenNegotiation = 'reOpenNegotiation',
    repeatSecondClosePeriod = 'repeatSecondClosePeriod',
    finishRepeatSecondClosePeriod = 'finishRepeatSecondClosePeriod',
    startRandomPeriod = 'startRandomPeriod',
    revertCancel = 'revertCancel',
    doDefineCollocations = 'doDefineCollocations',
    changeLotStatusToNegotiation = 'changeLotStatusToNegotiation',
    verificationOfProposalCompliance = 'verificationOfProposalCompliance',
}
