

import { FC, useEffect, useState } from 'react';
import Wrapper from '../Common/wrapper';
import Badge from '../Common/Badge';
import { pad } from '../../helpers/pad';
import { SocketEvent, SocketEvents } from '../../Interfaces/socketEvents';
import TinyQueue from 'tinyqueue';
import { timeout } from '../../helpers/timer';
import ApiService from 'services/api';
import { ProposalToConfirm } from 'services/api/interfaces';
import { formatValueWithAuctionRules } from 'helpers/currencyTransform';
import { AuctionNotice } from 'Interfaces/auctionNotice';
import { moment } from 'helpers/moment';
import BaseModal from './base-modal';

interface ModalVerificationOfProposalComplianceOrganizationProps {
    process: AuctionNotice;
    socketConnection: any;
}

const ModalVerificationOfProposalComplianceOrganization: FC<ModalVerificationOfProposalComplianceOrganizationProps> = ({
    socketConnection,
    process,
}) => {
    const [proposalsToConfirm, setProposalsToConfirm] = useState<ProposalToConfirm[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    let queueLock = false;
    const queueEvents: any = new TinyQueue([]);

    useEffect(() => {
        getProposalsToConfirm();
    }, []);

    const getProposalsToConfirm = async () => {
        try {
            setIsLoading(true);
            const data = await ApiService.getProposalsToConfirm(process.id);
            setProposalsToConfirm(data ?? []);
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const handleSocketEvents = () => {
        if (!socketConnection) {
            return;
        }

        socketConnection.on(SocketEvents.verificationOfProposalComplianceOrganization, (event: any) =>
            receiveEvent({
                type: SocketEvents.verificationOfProposalComplianceOrganization,
                message: event,
            })
        );
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type, message } = event;

                if (type === SocketEvents.verificationOfProposalComplianceOrganization)
                    return setProposalsToConfirm(message ?? []);

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };



    const getDefaultRender = (setIsModalVisible: (value: React.SetStateAction<boolean>) => void) =>
        proposalsToConfirm.map((proposalToConfirm) => {
            const span = (
                <span style={{ fontSize: '13px' }}>
                    {`Proposta de valor ${formatValueWithAuctionRules(
                        proposalToConfirm.value,
                        process.judgmentCriterion,
                        process.rateTypeBid
                    ).formatted}. `}
                    Hora limite  <b>{moment(proposalToConfirm.deadlineToConfirmProposal)}</b>
                </span>
            );
            return (
                <Wrapper
                    padding='7px 0'
                    flexBox
                    justifyContent='space-between'
                    borderBottom='1px #ebebeb solid'
                    minWidth='480px'
                    maxWidth='200px'
                >
                    <Wrapper flexBox>
                        <Badge
                            cursor='pointer'
                            padding='0 2px'
                            minWidth='37px'
                            width='38px'
                            color={'var(--platform-info-color)'}
                            title={`F`}
                            fontSize='9px'
                            style={{
                                marginRight: '8px',
                            }}
                        >
                            Lote {pad(proposalToConfirm.lot.item)}
                        </Badge>
                        {span}
                    </Wrapper>
                </Wrapper>
            );
        });

    const getConfirmations = (setIsModalVisible: (value: React.SetStateAction<boolean>) => void) => {
        if (isLoading) return <></>;

        if (!proposalsToConfirm.length) {
            return (<Wrapper flexBox justifyContent='center'>
                Nenhuma proposta atualizada automaticamente até o momento.
            </Wrapper>)
        }

        return getDefaultRender(setIsModalVisible);
    };

    return (<BaseModal getConfirmations={getConfirmations} proposalsToConfirm={proposalsToConfirm} />);
};

export default ModalVerificationOfProposalComplianceOrganization;
