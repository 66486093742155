import { FC, useEffect, useState, useRef } from 'react';
import { ModeOpenClosedProps } from './props';
import moment from 'moment';
import Counter from '../AuctionLots/components/Counter';
import { StageLot } from '../../Interfaces/stageLot';
import {
    checkIfPaused,
    differenceBetweenStartAndFinish,
    differenceBetweenPauses,
    alreadyStarted,
    diffBetweenNowAndExtensionTime,
    checkIfExistExtensionBid,
    differenceBetweenLastBidAndNow,
} from '../../helpers/lotPeriodShared';
import { currMoment } from '../../helpers/moment';
import { CONSTANTS } from '../../constants';
import * as workerTimers from 'worker-timers';
import { enableDefineCollocations } from 'helpers/enable-define-collocations.helper';

const zeroStages: string[] = [
    StageLot.convoked,
    StageLot.negotiation,
    StageLot.negotiation_finished,
    StageLot.canceled,
    StageLot.finished,
    StageLot.random_close_period_ended,
    StageLot.stopped,
    StageLot.awaiting_rejudge,
    StageLot.awaiting_call_provider,
    StageLot.awaiting_repeat_close_period,
    StageLot.no_winner_finished,
];

const ModeOpenClosed: FC<ModeOpenClosedProps> = ({
    auctionLot,
    title,
    changeStage,
    onFinishTimerLot,
    auctionNotice,
    bidPeriodCloseMode,
    serverTimestamp,
    helpText,
}) => {
    const [seconds, setSeconds] = useState(-1);
    const [visibleTimer, setVisibleTimer] = useState(true);
    const { OPEN_CLOSED_PERIOD, CLOSE_PERIOD, OPEN_PERIOD } = CONSTANTS.timersDuration;

    const intervalRef: any = useRef(null);

    const secondsRef: any = useRef(null);
    secondsRef.current = { seconds, setSeconds };

    const handleClearInterval = () => {
        try {
            !!intervalRef.current && workerTimers.clearInterval(intervalRef.current);
            intervalRef.current = null;
        } catch (error) {
            // desnecessário tratamento
        }
    };

    // força novo calculo de tempo faltante já que o countador de tempo não é preciso
    // dependendo do setInterval, se houver dessincronização o máximo que vai acontecer
    // é pular 10 segundos de uma vez..
    const cronValidateTimer = () => {
        if (zeroStages.includes(auctionLot.stage)) {
            return handleClearInterval();
        }

        try {
            intervalRef.current = workerTimers.setInterval(() => {
                validateLotTimer();
            }, 10000);
        } catch (error) {
            // desnecessário tratamento
        }
    };

    useEffect(() => {
        if (auctionLot.extensionTimeIsExpired && (auctionLot.stage === StageLot.random_close_period || auctionLot.stage === StageLot.random_period)) {
            return changeStage(StageLot.random_close_period_ended);
        }
    }, [auctionLot.stage])
    useEffect(() => {
        setVisibleTimer(true);
        cronValidateTimer();
        validateLotTimer();

        return () => handleClearInterval();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        auctionLot.bidStartDate,
        auctionLot.bidRenewDate,
        auctionLot.extensionTime,
        auctionLot.stage,
        auctionLot.winnerProvider,
        auctionLot.pauses,
    ]);

    const validateLotTimer = () => {
        if (
            auctionLot.stage === StageLot.random_close_period ||
            auctionLot.stage === StageLot.random_period ||
            auctionLot.stage === StageLot.random_close_period_ended ||
            auctionLot.stage === StageLot.random_period_ended ||
            auctionLot.stage === StageLot.verification_of_proposal_compliance
        ) return;

        if (zeroStages.includes(auctionLot.stage)) {
            return secondsRef.current.setSeconds(-1);
        }

        if (checkIfPaused({ auctionLot })) {
            secondsRef.current.setSeconds(-1);
            return changeStage(StageLot.paused);
        }

        if (
            auctionLot.stage === StageLot.close_period ||
            auctionLot.stage === StageLot.close_period_ended
        ) {
            const secondsToFinish = diffBetweenNowAndExtensionTime({
                auctionLot,
                closedPeriodTimer: CLOSE_PERIOD,
                serverDifference: serverTimestamp.difference,
            });

            if (secondsToFinish && secondsToFinish > 0) {
                secondsRef.current.setSeconds(secondsToFinish);
                return changeStage(StageLot.close_period);
            } else {
                return changeStage(StageLot.close_period_ended);
            }
        }

        if (
            auctionLot.stage === StageLot.second_close_period ||
            auctionLot.stage === StageLot.second_close_period_ended
        ) {
            const secondsToFinish = diffBetweenNowAndExtensionTime({
                auctionLot,
                closedPeriodTimer: CLOSE_PERIOD,
                serverDifference: serverTimestamp.difference,
            });

            if (secondsToFinish && secondsToFinish > 0) {
                secondsRef.current.setSeconds(secondsToFinish);
                return changeStage(StageLot.second_close_period);
            } else {
                return changeStage(StageLot.second_close_period_ended);
            }
        }

        if (
            auctionLot.stage === StageLot.repeat_second_close_period ||
            auctionLot.stage === StageLot.repeat_second_close_period_ended
        ) {
            const secondsToFinish = diffBetweenNowAndExtensionTime({
                auctionLot,
                closedPeriodTimer: CLOSE_PERIOD,
                serverDifference: serverTimestamp.difference,
            });

            if (secondsToFinish && secondsToFinish > 0) {
                secondsRef.current.setSeconds(secondsToFinish);
                return changeStage(StageLot.repeat_second_close_period);
            } else {
                return changeStage(StageLot.repeat_second_close_period_ended);
            }
        }

        const secondsDiffPause =
            differenceBetweenPauses({
                auctionLot,
                initPeriodPause: auctionLot.bidStartDate,
                serverDifference: serverTimestamp.difference,
            }) * -1;

        const difference = differenceBetweenStartAndFinish({
            auctionLot,
            pauseDiff: secondsDiffPause,
            periodTimer: enableDefineCollocations(auctionNotice.auctionTypeRules, auctionLot.hasWinner) ? OPEN_PERIOD : OPEN_CLOSED_PERIOD,
            serverDifference: serverTimestamp.difference,
        });

        const alreadyStartedLot = alreadyStarted({
            auctionLot,
            pauseDiff: secondsDiffPause,
            serverDifference: serverTimestamp.difference,
            periodTimer: OPEN_CLOSED_PERIOD,
        });

        if (alreadyStartedLot) {
            if (difference > 0) {
                secondsRef.current.setSeconds(Math.round(difference));
                return changeStage(StageLot.started);
            } else if (enableDefineCollocations(auctionNotice.auctionTypeRules, auctionLot.hasWinner) && auctionLot.status === 'open_period') {
                return validateDifferenceTwoMinutes();
            } else {
                return setDifferenceFiveMinutes();
            }
        }
    };

    const setDifferenceFiveMinutes = () => {
        const existBidExtension = checkIfExistExtensionBid({ auctionLot });

        const pauseDiff =
            differenceBetweenPauses({
                auctionLot,
                initPeriodPause: auctionLot.extensionTime,
                serverDifference: serverTimestamp.difference,
            }) * -1;

        const secondsRemaining = differenceBetweenStartAndExtensionTime(pauseDiff);

        // se nao tem extensionTime mostra timeEnded
        if (!existBidExtension && secondsRef.current.seconds <= 0) {
            return changeStage(StageLot.timeEnded);
        }

        if (enableDefineCollocations(auctionNotice.auctionTypeRules, auctionLot.hasWinner) && secondsRef.current.seconds <= 0 && auctionLot.status !== 'open_period') {
            return changeStage(StageLot.awaiting_define_collocations);
        }

        if (enableDefineCollocations(auctionNotice.auctionTypeRules, auctionLot.hasWinner) && secondsRef.current.seconds <= 0 && auctionLot.status === 'open_period') {
            return changeStage(StageLot.awaiting_define_collocations_ended);
        }

        if (existBidExtension && secondsRemaining > 0) {
            setVisibleTimer(false);
            secondsRef.current.setSeconds(secondsRemaining);
            return changeStage(StageLot.random_close_period);
        }
    };

    const differenceBetweenStartAndExtensionTime = (pauseDiff: number) => {
        const now = currMoment(serverTimestamp.difference).valueOf();

        const finish =
            auctionLot.extensionTime &&
            moment(auctionLot.extensionTime).subtract(pauseDiff, 'seconds');

        const secondsToFinish = finish && moment.duration(finish.diff(now)).asSeconds();
        return secondsToFinish && secondsToFinish > 0 ? secondsToFinish : 0;
    };

    const onFinishTimer = () => {
        secondsRef.current.setSeconds(-1);
        validateLotTimer();
        onFinishTimerLot();
    };

    const validateDifferenceTwoMinutes = () => {
        if (auctionLot.stage === StageLot.random_period) {
            return;
        }

        const existBidExtension = checkIfExistExtensionBid({ auctionLot });

        if (!existBidExtension && secondsRef.current.seconds <= 0) {
            return setDifferenceFiveMinutes();
        }

        const pauseDiff =
            differenceBetweenPauses({
                initPeriodPause: auctionLot.extensionTime,
                auctionLot,
                serverDifference: serverTimestamp.difference,
            }) * -1;

        const secondsRemaining = differenceBetweenLastBidAndNow(pauseDiff, serverTimestamp, auctionLot.extensionTime);

        if (existBidExtension) {
            if (secondsRemaining > 0) {
                secondsRef.current.setSeconds(secondsRemaining);
                return changeStage(StageLot.started);
            } else {
                return setDifferenceFiveMinutes();
            }
        }
    };

    return (
        <Counter
            title={title}
            secondsRemaining={secondsRef.current.seconds}
            onFinishTimer={onFinishTimer}
            visible={visibleTimer && bidPeriodCloseMode}
            helpText={helpText}
            isRandomClosePeriod={auctionLot.stage === StageLot.random_close_period}
        />
    );
};

export default ModeOpenClosed;
